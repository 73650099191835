// ---------------------------
//      Vanilla accordion
// ---------------------------

@import 'accordion.structure';

.accordion {
  &--handle {
    background-color: $colors-primary-alpha;
    border: inherit;
    color: $text-color-inverted;
    outline: none;
    padding: $atom-spacing;
    text-align: left;
    transition: all $animation-base-duration $animation-base-ease;
    width: 100%;

    &:hover,
    .active {
      background-color: $colors-secondary-alpha;
    }
  }

  &--panel {
    display: none;
    padding: $atom-spacing;
  }
}
