/**
 * Button variants
 *
 * Easily pump out default styles, as well as :hover, :focus, :active,
 * and disabled options for all buttons.
 */
@import '../iconography/mixins/mixins.material';

@mixin default-button() {
  @include user-select(none);

  @include button-icon('button');

  @include button-size-set(map-get($button-sizes, 'btn'), default);
  align-items: center;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border-color: transparent;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-weight: $btn-font-weight;
  justify-content: center;
  text-align: center;
  touch-action: manipulation;
  transition: $animation-base-property $animation-base-duration $animation-base-ease;

  &.#{$lib}btn--link,
  &.#{$lib}btn--link-secondary {
    @include button-size-set(map-get($button-sizes, 'link'), default);
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
    background-image: none;
    outline: 0;

    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &.disabled,
  &[disabled] {
    box-shadow: none;
    cursor: default;
    opacity: $btn-disabled-opacity;
  }

  .#{$lib}icon {
    margin-left: $btn-icon-spacing;
  }
}

@mixin button-variant($color, $background, $border, $hover-color: $color, $hover-background: darken($background, 30%), $hover-border: darken($color, 30%)) {
  @include svg-color($color);
  -webkit-appearance: initial;
  background: $background;
  color: $color;
  font-family: $btn-font-family;
  letter-spacing: $btn-letter-spacing;

  @if $border {
    border-color: $border;
  }

  &:focus,
  &.focus {
    @include svg-color($hover-color);
    background: $hover-background;
    color: $hover-color;

    @if $border {
      border-color: darken($border, 20%);
    }
  }

  &:hover {
    @include svg-color($hover-color);
    background: $hover-background;
    color: $hover-color;

    @if $border {
      border-color: $hover-border;
    }
  }

  &:active,
  &.active,
  &.is-active {
    @include svg-color($hover-color);
    background: $hover-background;
    color: $hover-color;

    @if $border {
      border-color: $hover-border;
    }

    &:hover,
    &:focus,
    &.focus {
      @include svg-color($hover-color);
      background: $hover-background;
      color: $hover-color;

      @if $border {
        border-color: $hover-border;
      }
    }
  }

  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &.focus {
      background: $background;

      @if $border {
        border-color: $border;
      }
    }
  }
}

/**
  * Button link variants
  *
  * Easily pump out default styles, as well as :hover, :focus, :active,
  * and disabled options for all buttons.
  */

@mixin button-link-variant($color, $hover-color, $hover-decoration, $disabled-color, $font-family, $font-weight) {
  @include svg-color($color);
  // 1. Zero out any padding, overrides any 0 based `calc()` functions.
  color: $color;
  justify-content: inherit;
  padding: 0; // 1.
  text-align: left;

  @if $font-family {
    font-family: $font-family;
  }

  @if $font-weight {
    font-weight: $font-weight;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }

  &:hover,
  &:focus {
    @include svg-color($hover-color);
    background-color: transparent;
    color: $hover-color;
    text-decoration: $hover-decoration;
  }

  &:active,
  &.active,
  &[disabled] {
    @include box-shadow(none);
    background-color: transparent;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus {
      @include svg-color($disabled-color);
      color: $disabled-color;
      text-decoration: none;
    }
  }
}

/**
  * Button tab
  */

@mixin tab-button-hover() {
  color: $btn-tab-hover-color;
  text-decoration: none;
}

@mixin tab-button-active() {
  @include tab-button-hover();
}

@mixin tab-button() {
  align-items: center;
  color: $btn-tab-color;
  display: flex;
  outline: none;
  position: relative;

  &:hover,
  &:focus {
    @include tab-button-hover();
  }
}

@mixin tab-button-icon() {
  @include icon-size('large');
  margin: $atom-spacing 0;

  @include breakpoint($desktop) {
    margin-right: $btn-icon-spacing;
  }
}

@mixin tab-button-decorated-hover() {
  &::after {
    height: 8px;

    @include breakpoint(0 $below-desktop) {
      height: 4px;
    }
  }
}

@mixin tab-button-decorated-active() {
  @include tab-button-decorated-hover();

  @include svg-color($btn-tab-decorated-active-color);
  color: $btn-tab-decorated-active-color;
}

@mixin tab-button-decorated() {
  outline: none;

  &::after {
    background-color: $btn-tab-decorated-border-color;
    bottom: 0;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 100%;
  }

  &:hover,
  &:focus {
    @include tab-button-decorated-hover();
  }
}

/**
  * Button sizes.
  */

@mixin button-size($padding-horizontal, $font-size, $border-radius, $grid-height-setting, $btn-border-size, $btn-margin, $btn-padding) {
  @include plumber-box(
    $margin: 0 $btn-margin,
    $border: $btn-border-size $btn-border-size,
    $padding: $btn-padding $btn-padding,
    $grid-height: $grid-height-setting
  );
  border-radius: $border-radius;
  border-width: $btn-border-size;
  font-size: $font-size;
  padding-left: $padding-horizontal;
  padding-right: $padding-horizontal;
}

@mixin button-size-set($map, $key) {
  $button-size: map-get($map, $key);

  @include button-size(
    nth($button-size, 1),
    nth($button-size, 2),
    nth($button-size, 3),
    nth($button-size, 4),
    nth($button-size, 5),
    nth($button-size, 6),
    nth($button-size, 7)
  );
}

@mixin button-icon($size: 'default') {
  .#{$lib}icon {
    @include icon-size($size);
    display: inline-block;
    vertical-align: middle;
  }
}
