// -----------------------
//      Nav Secondary 
// -----------------------

@import 'nav-secondary.structure';

.nav-secondary {
  list-style: none;

  a {
    background-color: $colors-primary-beta;
    display: block;
    padding: $atom-spacing ($atom-spacing * 2);
    text-align: center;
  }
}
