@import 'mixins/layout-helpers';

.#{$lib}container {
  @include clearfix;

  @include l-constrained;
  transition: padding $animation-base-duration $animation-base-ease, max-width $animation-base-duration $animation-base-ease;
}

/**
 * Feature spaced container.
 */
.#{$lib}container--feature {
  padding-bottom: $component-spacing;
  padding-top: $component-spacing;

  @include breakpoint($default-feature-container-size) {
    padding-bottom: $feature-spacing;
    padding-top: $feature-spacing;
  }
}

/**
 * Component spaced container.
 */
.#{$lib}container--component {
  padding-bottom: $component-spacing;
  padding-top: $component-spacing;
}

/**
 * Element spaced container.
 */
.#{$lib}container--element {
  padding-bottom: $element-spacing;
  padding-top: $element-spacing;
}

/**
 * Atom spaced container.
 */
.#{$lib}container--atom {
  padding-bottom: $atom-spacing;
  padding-top: $atom-spacing;
}

/**
 * Grid.
 */
.#{$lib}grid {
  display: grid;
  grid-gap: $default-ms-row-gap $default-ms-col-gap;

  &::before,
  &::after {
    display: none;
  }
}

/**
 * No scroll.
 */
.no-scroll {
  @include no-scroll();
}

/**
 * Sticky footer.
 * 1. https://gist.github.com/palicko/26b327cd2198e52c25b13485b4dd02bc
 */
.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 100vh;

  @media all
    and (device-width: 768px)
    and (device-height: 1024px)
    and (orientation: portrait) {
    min-height: 1024px;
  }

  @media all
    and (device-width: 768px)
    and (device-height: 1024px)
    and (orientation: landscape) {
    min-height: 768px;
  }

  @media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
    min-height: 635px;
  }

  @media only screen
    and (device-width: 414px)
    and (device-height: 736px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
    min-height: 621px;
  }

  @media only screen
    and (device-width: 375px)
    and (device-height: 667px)
    and (-webkit-device-pixel-ratio: 2)
    and (orientation: portrait) {
    min-height: 553px;
  }

  @media screen and (device-aspect-ratio: 40 / 71) {
    min-height: 500px;
  }

  @media screen
    and (device-width: 320px)
    and (device-aspect-ratio: 2 / 3)
    and (orientation: portrait) {
    min-height: 480px;
  }
}

.root-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1; // 1.
  flex-shrink: 1;
  position: relative;
}
