@import '../loader.settings';

.#{$lib}wandering-cubes {
  height: $loader-size;
  margin: $loader-spinner-margin;
  position: relative;
  width: $loader-size;

  &__cube {
    animation: wandering-cube $loader-wandering-cubes-animation-duration ease-in-out #{-$loader-wandering-cubes-animation-duration} infinite both;
    background-color: $loader-color;
    height: 10px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  &__cube2 {
    animation-delay: -$loader-wandering-cubes-animation-duration / 2;
  }
}

@keyframes wandering-cube {
  $cube-distance: 30px;

  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: translateX($cube-distance) rotate(-90deg) scale(0.5);
  }

  50% {

    /* Hack to make FF rotate in the right direction */
    transform: translateX($cube-distance) translateY($cube-distance) rotate(-179deg);
  }

  50.1% {
    transform: translateX($cube-distance) translateY($cube-distance) rotate(-180deg);
  }

  75% {
    transform: translateX(0) translateY($cube-distance) rotate(-270deg) scale(0.5);
  }

  100% {
    transform: rotate(-360deg);
  }
}
