// ---------------------------------
//      Nav Secondary structure
// ---------------------------------

@import 'nav-secondary.settings';

.nav-list {
  margin: 0;
  padding: 0;

  @include breakpoint($tablet) {
    display: inline-flex;
  }
}
