// -----------------------------------
//      http://type-scale.com
//
//      default : Major Third
//      tablet : Augmented Fourth
//      desktop : Golden Ratio
// -----------------------------------

// ---------------------
//     Headings map
//
//     breakpoint: (font-size, line-height, leading-space, trailing-space)
// ---------------------
$vr-headings: (
  h1: (default: (39px, 6, 1, 2), phablet: (45px, 8, 1, 2), tablet: (64px, 8, 1, 2), desktop: (109px, 14, 1, 2)),
  h2: (default: (31px, 6, 1, 2), tablet: (45px, 6, 1, 2), desktop: (67px, 10, 1, 2)),
  h3: (default: (25px, 4, 1, 2), tablet: (31px, 4, 1, 2), desktop: (41px, 6, 1, 2)),
  h4: (default: (20px, 3, 1, 2), tablet: (22px, 3, 1, 2), desktop: (25px, 3, 1, 2)),
  h5: (default: (16px, 3, 1, 2), tablet: (16px, 3, 1, 2), desktop: (16px, 3, 1, 2)),
  h6: (default: (12px, 3, 1, 2), tablet: (11px, 3, 1, 2), desktop: (9px, 3, 1, 2))
) !default;

// -----------------------------
//     Display Headings map
// -----------------------------
$vr-display-headings: (
  h1: (default: (39px, 6, 1, 2), tablet: (63px, 8, 1, 2), desktop: (109px, 14, 1, 2)),
  h2: (default: (31px, 6, 1, 2), tablet: (45px, 6, 1, 2), desktop: (67px, 8, 1, 2)),
  h3: (default: (25px, 4, 1, 2), tablet: (31px, 4, 1, 2), desktop: (41px, 6, 1, 2)),
  h4: (default: (20px, 4, 1, 2), tablet: (22px, 4, 1, 2), desktop: (25px, 4, 1, 2)),
  h5: (default: (16px, 4, 1, 2), tablet: (16px, 4, 1, 2), desktop: (16px, 4, 1, 2)),
  h6: (default: (12px, 4, 1, 2), tablet: (11px, 4, 1, 2), desktop: (9px, 4, 1, 2))
) !default;

/**
 * Headings Font Settings.
 */
$font-family-heading: $font-family-sans-serif !default;
$font-family-heading-italic: $font-family-sans-serif !default;
$font-family-heading-display: $font-family-sans-serif !default;
$font-family-heading-display-italic: $font-family-sans-serif !default;
$font-family-heading-weight: bold !default;
$font-family-heading-italic-weight: bold !default;
$font-family-heading-display-weight: bold !default;
$font-family-heading-display-italic-weight: bold !default;

/**
  * Heading font-sizes.
  */
$font-size-h1: floor(($font-size-base * 2.6)) !default;
$font-size-h2: floor(($font-size-base * 2.15)) !default;
$font-size-h3: ceil(($font-size-base * 1.7)) !default;
$font-size-h4: ceil(($font-size-base * 1.25)) !default;
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default;

/**
  * Font Family baseline.
  */
$default-heading-font-baseline: $default-font-baseline !default;
