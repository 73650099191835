/**
 * Buttons
 *
 * For each of BASE's buttons,
 * define text, background and
 * border color.
 */

$btn-font-weight: normal !default;
$btn-font-family: $font-family-base !default;
$btn-border-size: 1px !default;
$btn-link-border-size: 0 !default;
$btn-letter-spacing: inherit !default;

$btn-link-font-family: false !default;
$btn-link-font-weight: false !default;
$btn-link-secondary-font-family: false !default;
$btn-link-secondary-font-weight: false !default;

$btn-disabled-opacity: 0.5 !default;
$btn-text-transform: none !default;
$btn-box-shadow: none !default;

$btn-default-color: #000 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #000 !default;
$btn-default-color-hover: #000 !default;
$btn-default-bg-hover: #fff !default;
$btn-default-border-hover: #000 !default;

$btn-default-padding: $default-grid-height !default;
$btn-small-padding: $default-grid-height !default;
$btn-large-padding: $default-grid-height !default;
$btn-link-default-padding: 0 !default;
$btn-link-small-padding: 0 !default;
$btn-link-large-padding: 0 !default;

$btn-default-grid-height: $atom-spacing !default;
$btn-small-grid-height: $atom-spacing !default;
$btn-large-grid-height: $element-spacing !default;
$btn-link-default-grid-height: $btn-default-grid-height !default;
$btn-link-small-grid-height: $btn-small-grid-height !default;
$btn-link-large-grid-height: $btn-large-grid-height !default;

$btn-default-inverted-color: $btn-default-bg !default;
$btn-default-inverted-bg: $btn-default-color !default;
$btn-default-inverted-border: $btn-default-color !default;
$btn-default-inverted-bg-hover: darken($btn-default-inverted-bg, 30%) !default;
$btn-default-inverted-color-hover: darken($btn-default-inverted-color, 30%) !default;

$btn-default-outline-color: $btn-default-color !default;
$btn-default-outline-bg: transparent !default;
$btn-default-outline-border: $btn-default-color !default;
$btn-default-outline-color-hover: darken($btn-default-outline-color, 30%) !default;
$btn-default-outline-bg-hover: darken($btn-default-outline-bg, 30%) !default;
$btn-default-outline-border-hover: darken($btn-default-outline-border, 30%) !default;

$btn-primary-color: #000 !default;
$btn-primary-bg: #fff !default;
$btn-primary-border: #000 !default;
$btn-primary-color-hover: #fff !default;
$btn-primary-bg-hover: #000 !default;
$btn-primary-border-hover: #000 !default;

$btn-primary-inverted-color: $btn-primary-bg !default;
$btn-primary-inverted-bg: $btn-primary-color !default;
$btn-primary-inverted-border: $btn-primary-color !default;
$btn-primary-inverted-color-hover: $btn-primary-bg !default;
$btn-primary-inverted-bg-hover: $btn-primary-color !default;
$btn-primary-inverted-border-hover: $btn-primary-color !default;

$btn-primary-outline-color: $btn-primary-bg !default;
$btn-primary-outline-bg: transparent !default;
$btn-primary-outline-border: $btn-primary-bg !default;
$btn-primary-outline-color-hover: $btn-primary-bg !default;
$btn-primary-outline-bg-hover: transparent !default;
$btn-primary-outline-border-hover: $btn-primary-bg !default;

$btn-secondary-color: #fff !default;
$btn-secondary-bg: $colors-secondary-alpha !default;
$btn-secondary-border: darken($btn-secondary-bg, 5%) !default;
$btn-secondary-color-hover: #fff !default;
$btn-secondary-bg-hover: $colors-secondary-alpha !default;
$btn-secondary-border-hover: darken($btn-secondary-bg, 5%) !default;

$btn-secondary-inverted-color: $btn-secondary-bg !default;
$btn-secondary-inverted-bg: $btn-secondary-color !default;
$btn-secondary-inverted-border: $btn-secondary-color !default;
$btn-secondary-inverted-color-hover: $btn-secondary-bg !default;
$btn-secondary-inverted-bg-hover: $btn-secondary-color !default;
$btn-secondary-inverted-border-hover: $btn-secondary-color !default;

$btn-secondary-outline-color: $btn-secondary-bg !default;
$btn-secondary-outline-bg: transparent !default;
$btn-secondary-outline-border: $btn-secondary-bg !default;
$btn-secondary-outline-color-hover: $btn-secondary-bg !default;
$btn-secondary-outline-bg-hover: transparent !default;
$btn-secondary-outline-border-hover: $btn-secondary-bg !default;

$btn-tertiary-color: #fff !default;
$btn-tertiary-bg: $colors-tertiary-alpha !default;
$btn-tertiary-border: darken($btn-tertiary-bg, 5%) !default;

$btn-tertiary-inverted-color: $btn-tertiary-bg !default;
$btn-tertiary-inverted-bg: $btn-tertiary-color !default;
$btn-tertiary-inverted-border: $btn-tertiary-color !default;
$btn-tertiary-inverted-color-hover: $btn-tertiary-bg !default;
$btn-tertiary-inverted-bg-hover: $btn-tertiary-color !default;
$btn-tertiary-inverted-border-hover: $btn-tertiary-color !default;

$btn-tertiary-outline-color: $btn-tertiary-bg !default;
$btn-tertiary-outline-bg: transparent !default;
$btn-tertiary-outline-border: $btn-tertiary-bg !default;

$btn-success-color: #fff !default;
$btn-success-bg: $colors-alert--success !default;
$btn-success-border: darken($colors-alert--success, 5%) !default;

$btn-success-inverted-color: $btn-success-bg !default;
$btn-success-inverted-bg: $btn-success-color !default;
$btn-success-inverted-border: $btn-success-color !default;
$btn-success-inverted-color-hover: $btn-success-bg !default;
$btn-success-inverted-bg-hover: $btn-success-color !default;
$btn-success-inverted-border-hover: $btn-success-color !default;

$btn-success-outline-color: $btn-success-bg !default;
$btn-success-outline-bg: transparent !default;
$btn-success-outline-border: $btn-success-bg !default;

$btn-info-color: #fff !default;
$btn-info-bg: $colors-primary-alpha !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;

$btn-info-inverted-color: $btn-info-bg !default;
$btn-info-inverted-bg: $btn-info-color !default;
$btn-info-inverted-border: $btn-info-color !default;

$btn-info-outline-color: $btn-info-bg !default;
$btn-info-outline-bg: transparent !default;
$btn-info-outline-border: $btn-info-bg !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $colors-alert--warning !default;
$btn-warning-border: darken($colors-alert--warning, 5%) !default;

$btn-warning-inverted-color: $btn-warning-bg !default;
$btn-warning-inverted-bg: $btn-warning-color !default;
$btn-warning-inverted-border: $btn-warning-color !default;

$btn-warning-outline-color: $btn-warning-bg !default;
$btn-warning-outline-bg: transparent !default;
$btn-warning-outline-border: $btn-warning-bg !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $colors-alert--error !default;
$btn-danger-border: darken($colors-alert--error, 5%) !default;

$btn-danger-inverted-color: $btn-danger-bg !default;
$btn-danger-inverted-bg: $btn-danger-color !default;
$btn-danger-inverted-border: $btn-danger-color !default;

$btn-danger-outline-color: $btn-danger-bg !default;
$btn-danger-outline-bg: transparent !default;
$btn-danger-outline-border: $btn-danger-bg !default;

$btn-link-font-weight: $btn-font-weight !default;
$btn-link-disabled-color: $colors-greys-alpha !default;
$btn-link-hover-decoration: $link-hover-decoration !default;
$btn-link-inverted-disabled-color: $link-color-inverted-disabled !default;

$btn-border-radius-base: $border-radius-base !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default;
$btn-link-border-radius-base: 0 !default;
$btn-link-border-radius-large: 0 !default;
$btn-link-border-radius-small: 0 !default;

$btn-font-size-base: $font-size-base !default;
$btn-font-size-small: 12px !default;
$btn-font-size-large: 24px !default;
$btn-link-font-size-base: $font-size-base !default;
$btn-link-font-size-small: $btn-font-size-small !default;
$btn-link-font-size-large: $btn-font-size-large !default;

$btn-icon-spacing: $atom-spacing !default;

$btn-tab-color: $btn-default-color !default;
$btn-tab-hover-color: $btn-default-color !default;

$btn-tab-decorated-border-color: $btn-default-border !default;
$btn-tab-decorated-active-color: $btn-default-color !default;

/**
  * Button sizes map
  * $padding-horizontal, $font-size, $border-radius, $grid-height-setting, $btn-border-size, $btn-margin, $btn-padding
  */
$button-sizes: (
  btn:
  (
    small: ($btn-small-padding, $btn-font-size-small, $btn-border-radius-small, $btn-small-grid-height, $btn-border-size, 0, 1),
    default: ($btn-default-padding, $btn-font-size-base, $btn-border-radius-base, $btn-default-grid-height, $btn-border-size, 0, 2),
    large: ($btn-large-padding, $btn-font-size-large, $btn-border-radius-large, $btn-large-grid-height, $btn-border-size, 0, 1)
  ),
  btn-fills:
  (
    small: ($btn-small-padding, $btn-font-size-small, $btn-border-radius-small, $btn-small-grid-height, 0, 0, 1),
    default: ($btn-default-padding, $btn-font-size-base, $btn-border-radius-base, $btn-default-grid-height, 0, 0, 2),
    large: ($btn-large-padding, $btn-font-size-large, $btn-border-radius-large, $btn-large-grid-height, 0, 0, 1)
  ),
  link:
  (
    small: ($btn-link-small-padding, $btn-link-font-size-small, $btn-link-border-radius-small, $btn-link-small-grid-height, $btn-link-border-size, 0, 0),
    default: ($btn-link-default-padding, $btn-link-font-size-base, $btn-link-border-radius-base, $btn-link-default-grid-height, $btn-link-border-size, 0, 0),
    large: ($btn-link-large-padding, $btn-link-font-size-large, $btn-link-border-radius-large, $btn-link-large-grid-height, $btn-link-border-size, 0, 0)
  )
)
  !default;

/**
  * Button fills map
  */
$button-fills: (
  gradient-alpha: (background: $gradient-ui-alpha, inverted: true),
  gradient-beta: (background: $gradient-ui-beta),
  gradient-gamma: (background: $gradient-ui-gamma, inverted: true),
  gradient-delta: (background: $gradient-ui-delta, inverted: true),
  gradient-epsilon: (background: $gradient-ui-epsilon, inverted: true),
  gradient-zeta: (background: $gradient-ui-zeta, inverted: true),
  gradient-eta: (background: $gradient-ui-eta, inverted: true),
  gradient-theta: (background: $gradient-ui-theta),
  gradient-iota: (background: $gradient-ui-iota, inverted: true),
  gradient-kappa: (background: $gradient-ui-kappa, inverted: true),
  gradient-lambda: (background: $gradient-ui-lambda, inverted: true),
  gradient-mu: (background: $gradient-ui-mu, inverted: true),
  gradient-nu: (background: $gradient-ui-nu, inverted: true),
  gradient-xi: (background: $gradient-ui-xi, inverted: true),
  gradient-omicron: (background: $gradient-ui-omicron, inverted: true),
  gradient--pi: (background: $gradient-ui-pi, inverted: true),
  gradient-secondary-alpha: (background: $gradient-ui-secondary-alpha, inverted: true),
  gradient-secondary-beta: (background: $gradient-ui-secondary-beta),
  gradient-secondary-gamma: (background: $gradient-ui-secondary-gamma, inverted: true),
  gradient-secondary-delta: (background: $gradient-ui-secondary-delta, inverted: true),
  gradient-secondary-epsilon: (background: $gradient-ui-secondary-epsilon, inverted: true),
  gradient-secondary-zeta: (background: $gradient-ui-secondary-zeta, inverted: true),
  gradient-secondary-eta: (background: $gradient-ui-secondary-eta, inverted: true),
  gradient-secondary-theta: (background: $gradient-ui-secondary-theta),
  gradient-secondary-iota: (background: $gradient-ui-secondary-iota, inverted: true),
  gradient-secondary-kappa: (background: $gradient-ui-secondary-kappa, inverted: true),
  gradient-secondary-lambda: (background: $gradient-ui-secondary-lambda, inverted: true),
  gradient-secondary-mu: (background: $gradient-ui-secondary-mu, inverted: true),
  gradient-secondary-nu: (background: $gradient-ui-secondary-nu, inverted: true),
  gradient-secondary-xi: (background: $gradient-ui-secondary-xi, inverted: true),
  gradient-secondary-omicron: (background: $gradient-ui-secondary-omicron, inverted: true),
  gradient-secondary-pi: (background: $gradient-ui-secondary-pi, inverted: true)
)
  !default;

$button-fill-color: $btn-default-color !default;
$button-fill-inverted-color: $btn-default-inverted-color !default;
