/**
 * Fonts.
 */

/**
  * Default webfont file path.
  */

$icon-font-path: '../../fonts/' !default;

/**
 * Font, line-height, and color for body text, headings, and more.
 */
$font-family-sans-serif: Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
