// -------------------------
//      Modal Settings
// -------------------------

/**
 * Dialog element.
 */
$modal-background-color: $colors-utility-white !default;

$modal-padding: 0 !default;

/**
 * Modifiers.
 */
$modal-default-border-radius: $border-radius-base !default;
$modal-default-border-style: none !default;
$modal-default-max-height: 90vh !default;
$modal-default-max-width: 60rem !default;
$modal-default-width-gutter: $default-ms-col-gap !default;

$modal-default-mobile-border-radius: 0 !default;
$modal-default-mobile-height: 100% !default;

$modal-fullscreen-border-radius: 0 !default;
$modal-fullscreen-border-style: none !default;

/**
 * Backdrop.
 */
$modal-backdrop-color: rgba($colors-utility-black, 0.4) !default;
