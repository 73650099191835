
@if index($hamburger-types, arrow) {

  /*
   * Arrow
   */
  .#{$lib}hamburger--arrow.is-active {
    .#{$lib}hamburger__inner {
      &::before {
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
      }

      &::after {
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
      }
    }
  }
}
