@import 'buttons.structure';

// -----------------
//      Buttons
// -----------------

.#{$lib}btn {
  @include user-select(none);

  @include button-icon('button');

  @include button-size-set(map-get($button-sizes, 'btn'), default);

  @include box-shadow($btn-box-shadow);
  align-items: center;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border-color: transparent;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-weight: $btn-font-weight;
  justify-content: center;
  text-align: center;
  text-transform: $btn-text-transform;
  touch-action: manipulation;
  transition: $animation-base-property $animation-base-duration $animation-base-ease;

  &.#{$lib}btn--link,
  &.#{$lib}btn--link-secondary {
    @include button-size-set(map-get($button-sizes, 'link'), default);
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
    background-image: none;
    outline: 0;

    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &.disabled,
  &[disabled] {
    box-shadow: none;
    cursor: default;
    opacity: $btn-disabled-opacity;
  }

  .#{$lib}icon {
    margin-left: $btn-icon-spacing;
  }
}

// --------------------------------
//      Icon buttons positions
// --------------------------------

.#{$lib}btn--icon-ends {
  .#{$lib}icon {
    margin-left: $btn-icon-spacing;
    margin-right: $btn-icon-spacing;
  }
}

.#{$lib}btn--icon-start {
  .#{$lib}icon {
    margin-left: 0;
    margin-right: $btn-icon-spacing;
  }
}

.#{$lib}btn--icon-end {
  .#{$lib}icon {
    margin-left: $btn-icon-spacing;
    margin-right: 0;
  }
}

.#{$lib}btn--icon-both {
  .#{$lib}icon {
    &:nth-of-type(1) {
      margin-left: 0;
      margin-right: $btn-icon-spacing;
    }

    &:nth-last-of-type(1) {
      margin-left: $btn-icon-spacing;
      margin-right: 0;
    }
  }
}

// ---------------------------
//      Alternate buttons
// ---------------------------

.#{$lib}btn--default {
  @include button-variant(
    $btn-default-color,
    $btn-default-bg,
    $btn-default-border,
    $btn-default-color-hover,
    $btn-default-bg-hover,
    $btn-default-border-hover
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-default-inverted-color,
      $btn-default-inverted-bg,
      $btn-default-inverted-border,
      $btn-default-inverted-color-hover,
      $btn-default-inverted-bg-hover
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-default-outline-color,
      $btn-default-outline-bg,
      $btn-default-outline-border,
      $btn-default-outline-color-hover,
      $btn-default-outline-bg
    );
  }
}

/**
 * Primary.
 */
.#{$lib}btn--primary {
  @include button-variant(
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border,
    $btn-primary-color-hover,
    $btn-primary-bg-hover,
    $btn-primary-border-hover,
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-primary-inverted-color,
      $btn-primary-inverted-bg,
      $btn-primary-inverted-border,
      $btn-primary-inverted-color-hover,
      $btn-primary-inverted-bg-hover,
      $btn-primary-inverted-border-hover
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-primary-outline-color,
      $btn-primary-outline-bg,
      $btn-primary-outline-border,
      $btn-primary-outline-color-hover,
      $btn-primary-outline-bg-hover,
      $btn-primary-outline-border-hover
    );
  }
}

/**
 * Secondary.
 */
.#{$lib}btn--secondary {
  @include button-variant(
    $btn-secondary-color,
    $btn-secondary-bg,
    $btn-secondary-border
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-secondary-inverted-color,
      $btn-secondary-inverted-bg,
      $btn-secondary-inverted-border,
      $btn-secondary-inverted-color-hover,
      $btn-secondary-inverted-bg-hover,
      $btn-secondary-inverted-border-hover
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-secondary-outline-color,
      $btn-secondary-outline-bg,
      $btn-secondary-outline-border,
      $btn-secondary-outline-color-hover,
      $btn-secondary-outline-bg-hover,
      $btn-secondary-outline-border-hover
    );
  }
}

/**
 * Tertiary.
 */
.#{$lib}btn--tertiary {
  @include button-variant(
    $btn-tertiary-color,
    $btn-tertiary-bg,
    $btn-tertiary-border
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-tertiary-inverted-color,
      $btn-tertiary-inverted-bg,
      $btn-tertiary-inverted-border,
      $btn-tertiary-inverted-color-hover,
      $btn-tertiary-inverted-bg-hover,
      $btn-tertiary-inverted-border-hover
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-tertiary-outline-color,
      $btn-tertiary-outline-bg,
      $btn-tertiary-outline-border,
      $hover-color: darken($btn-tertiary-outline-color, 30%)
    );
  }
}

.#{$lib}btn--success {
  @include button-variant(
    $btn-success-color,
    $btn-success-bg,
    $btn-success-border
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-success-inverted-color,
      $btn-success-inverted-bg,
      $btn-success-inverted-border,
      $btn-success-inverted-color-hover,
      $btn-success-inverted-bg-hover
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-success-outline-color,
      $btn-success-outline-bg,
      $btn-success-outline-border,
      $hover-color: darken($btn-success-outline-color, 30%)
    );
  }
}

.#{$lib}btn--info {
  @include button-variant(
    $btn-info-color,
    $btn-info-bg,
    $btn-info-border
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-info-inverted-color,
      $btn-info-inverted-bg,
      $btn-info-inverted-border,
      $btn-info-inverted-color,
      $btn-info-inverted-bg
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-info-outline-color,
      $btn-info-outline-bg,
      $btn-info-outline-color,
      $btn-info-outline-border
    );
  }
}

.#{$lib}btn--warning {
  @include button-variant(
    $btn-warning-color,
    $btn-warning-bg,
    $btn-warning-border
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-warning-inverted-color,
      $btn-warning-inverted-bg,
      $btn-warning-inverted-border,
      $btn-warning-inverted-color,
      $btn-warning-inverted-bg
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-warning-outline-color,
      $btn-warning-outline-bg,
      $btn-warning-outline-border,
      $btn-warning-outline-color,
      $btn-warning-outline-bg
    );
  }
}

.#{$lib}btn--danger {
  @include button-variant(
    $btn-danger-color,
    $btn-danger-bg,
    $btn-danger-border
  );

  &.#{$lib}btn--inverted {
    @include button-variant(
      $btn-danger-inverted-color,
      $btn-danger-inverted-bg,
      $btn-danger-inverted-border,
      $btn-danger-inverted-color,
      $btn-danger-inverted-bg
    );
  }

  &.#{$lib}btn--outline {
    @include button-variant(
      $btn-danger-outline-color,
      $btn-danger-outline-bg,
      $btn-danger-outline-border,
      $btn-danger-outline-color,
      $btn-danger-outline-bg,
      $btn-danger-outline-border
    );
  }
}

.#{$lib}btn--social {
  &__google {
    @include button-variant(
      $colors-utility-white,
      $google-red,
      $google-red,
    );
  }

  &__facebook {
    @include button-variant(
      $colors-utility-white,
      $facebook-blue,
      $facebook-blue,
    );
  }

  &__twitter {
    @include button-variant(
      $colors-utility-white,
      $twitter-blue,
      $twitter-blue,
    );
  }
}

// ----------------------
//      Button sizes
// ----------------------

.#{$lib}btn--small {
  @include button-size-set(map-get($button-sizes, 'btn'), small);

  @include button-icon('button-small');

  &.#{$lib}btn--link,
  &.#{$lib}btn--link-secondary {
    @include button-size-set(map-get($button-sizes, 'link'), small);
  }
}

.#{$lib}btn--large {
  @include button-size-set(map-get($button-sizes, 'btn'), large);

  @include button-icon('button-large');

  &.#{$lib}btn--link,
  &.#{$lib}btn--link-secondary {
    @include button-size-set(map-get($button-sizes, 'link'), large);
  }
}

// ----------------------
//      Link buttons
// ----------------------

.#{$lib}btn--link {
  @include button-link-variant(
    $link-color,
    $link-hover-color,
    $btn-link-hover-decoration,
    $btn-link-disabled-color,
    $btn-link-font-family,
    $btn-link-font-weight
  );

  &.#{$lib}btn--inverted {
    @include button-link-variant(
      $link-color-inverted,
      $link-color-inverted-hover,
      $btn-link-hover-decoration,
      $btn-link-inverted-disabled-color,
      $btn-link-font-family,
      $btn-link-font-weight
    );
  }
}

.#{$lib}btn--link-secondary {
  @include button-link-variant(
    $link-color-secondary,
    $link-color-secondary-hover,
    $btn-link-hover-decoration,
    $btn-link-disabled-color,
    $btn-link-secondary-font-family,
    $btn-link-secondary-font-weight
  );

  &.#{$lib}btn--inverted {
    @include button-link-variant(
      $link-color-inverted,
      $link-color-inverted-hover,
      $btn-link-hover-decoration,
      $btn-link-inverted-disabled-color,
      $btn-link-secondary-font-family,
      $btn-link-secondary-font-weight
    );
  }
}

/**
 * Button fills.
 */

@each $fill, $map in $button-fills {
  .#{$lib}btn--fill-#{$fill} {
    $inverted: map_get($map, 'inverted');
    $background: map_get($map, 'background');
    $color: $button-fill-color;
    $border: transparent;

    @if $inverted {
      $color: $button-fill-inverted-color;
    }

    @include button-variant($color, $background, $border, $color, $background, $border);

    @include button-size-set(map-get($button-sizes, 'btn-fills'), default);

    &.#{$lib}btn--small {
      @include button-size-set(map-get($button-sizes, 'btn-fills'), small);
    }

    &.#{$lib}btn--large {
      @include button-size-set(map-get($button-sizes, 'btn-fills'), large);
    }
  }
}

// ------------------
//      Circular
// ------------------

.#{$lib}btn--round {
  border-radius: 50%;
  padding: $padding-base-vertical;

  .#{$lib}icon {
    margin-left: 0;
    margin-right: 0;
  }
}

// ----------------------
//      Block button
// ----------------------

.#{$lib}btn--block {
  width: 100%;
}

.#{$lib}btn--block + .#{$lib}btn--block {
  margin-top: 5px;
}
