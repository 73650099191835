@import 'site-header.settings';

.header {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @include breakpoint($tablet) {
    grid-template-columns: repeat(16, 1fr);

    .logo {
      grid-column: 1 / span 3;
    }

    .nav {
      grid-column: 5 / span 7;
    }

    .search-form {
      grid-column: 13 / span 4;
    }
  }

  .logo {
    grid-column: 5 / span 4;
  }

  .nav {
    grid-column: 3 / span 8;
  }

  .search-form {
    grid-column: 1 / span 12;
  }
}
