@import 'forms.structure';

/**
 * Forms normalize inputs.
 */

$inputs: (
  'text',
  'email',
  'url',
  'password',
  'search',
  'file',
  'date',
  'number',
  'datetime',
  'datetime-local',
  'month',
  'time',
  'week',
  'newsletter'
);
$elements: ('textarea');
$focus-inputs: (
  'text',
  'email',
  'url',
  'password',
  'search',
  'number',
  'textarea'
);
$placeholder-inputs: (
  'text',
  'email',
  'url',
  'password',
  'search',
  'textarea',
  'number'
);

@each $input in $inputs {
  $selector: unquote('.#{$lib}form-item__input--#{$input}');

  #{$selector} {
    @include base-input-style();
  }
}

@each $el in $elements {
  $selector: unquote('.#{$lib}form-item__#{$el}');

  #{$selector} {
    @include base-input-style();
  }
}

/** Custom cross-browser select styling. */
.#{$lib}form-select {
  @include base-input-style();
  height: auto;
  padding: 0;
  position: relative;

  select {

    /** Disable default styling on ff. */
    -moz-appearance: none;

    /** Disable default styling on webkit browsers. */
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    height: 100%;
    outline: none;
    padding: $select-padding;
    padding-right: 35px;
    position: relative;
    width: 100%;
    z-index: 1;

    /** Disable ugly ass outline on firefox. */
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    /** Disable default arrow on IE 11+. */
    &::-ms-expand {
      display: none;
    }
  }

  &__arrow {
    align-items: center;
    bottom: 0;
    display: flex;
    position: absolute;
    right: $atom-spacing;
    top: 0;
  }
}

/**
 * Form.
 */

.#{$lib}form {
  background: $form-bg-color;
  border: $form-border-width $form-border-style $form-border-color;
}

.#{$lib}form-item {
  @include plumber-box($margin: $form-item-vr-margin-top $form-item-vr-margin-bottom, $grid-height: $form-item-vr-grid-height);
  background: $form-item-bg-color;
  display: flex;
  flex-direction: column;
  position: relative;

  /**
  * Labels.
  */

  &__label {
    @include vr-plumbing-set($vr-forms, labels);
    color: $label-color;
    display: $label-default-display;
    font-family: $label-default-font-family;
    font-size: $label-default-font-size;
    max-width: 100%;
    order: 0;
    padding-left: $label-spacing-left;
    padding-right: $label-spacing-right;
    position: relative;
    text-transform: $label-text-transform;
    white-space: nowrap;
  }

  &__input {
    order: 1;

    @each $input in $focus-inputs {
      $selector: unquote('&--#{$input}:focus');

      #{$selector} {
        border-color: $input-border-focus;
        color: $input-color-focus;
      }
    }

    @each $input in $placeholder-inputs {
      $selector: unquote('&--#{$input}::placeholder');

      #{$selector} {
        color: $input-color-placeholder;
        font-style: $input-font-style-placeholder;
      }
    }
  }

  &__textarea {
    @include base-input-style();
    display: block;
    min-height: ($input-height-base * 3);
    order: 1;
    resize: none;

    &:focus {
      border-color: $input-border-focus;
      color: $input-color-focus;
    }

    &::placeholder {
      color: $input-color-placeholder;
      font-style: $input-font-style-placeholder;
    }
  }

  &__required {
    color: $label-required-color;
  }

  &__optional {
    color: $label-optional-color;
    font-family: $label-optional-font-family;
    font-weight: $label-optional-font-weight;
    margin-left: $atom-spacing;
  }

  /**
   * Checkboxes and radios.
   */

  &__checkbox,
  &__radio {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  &__checkbox + label {
    padding-left: 24px;
  }

  &__checkbox + label::before,
  &__radio + label::before {
    display: inline-block;
  }

  &__checkbox + label::before {
    background-color: $label-bg-color;
    border: $checkbox-border-size solid $input-border-color;
    border-radius: $checkbox-border-radius;
    content: '';
    display: block;
    height: $input-height-base;
    left: 0;
    position: absolute;
    width: $input-height-base;
  }

  &__radio + label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    padding-left: $input-height-base + ($input-height-base / 2);
    position: relative;
    vertical-align: bottom;

    &::before,
    &::after {
      border-radius: 50%;
      content: '';
      position: absolute;
      transition: $input-animation-property $input-animation-duration $input-animation-ease;
      transition-property: transform, $input-border-color;
    }

    &::before {
      background: $input-bg;
      border: $radio-border-size solid $input-border-color;
      height: $input-height-base;
      left: 0;
      width: $input-height-base;
    }

    &::after {
      background: $radio-color-focus;
      height: $radio-fill-size;
      left: $input-height-base / 2 - $radio-fill-size / 2;
      margin-top: $input-height-base / 2 - $radio-fill-size / 2;
      transform: scale(0);
      width: $radio-fill-size;
    }
  }

  &__checkbox:checked + label::before {
    background: $checkbox-active-color
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='#{$checkbox-svg-viewbox}' fill='#{$checkbox-focus-icon-color}' stroke='none'><path d='#{$checkbox-svg-path}'></path></svg>")
      no-repeat;
    border-color: $checkbox-active-color;
  }

  &__checkbox:focus + label::before,
  &__checkbox:focus:checked + label::before {
    border-color: $checkbox-focus-color;
  }

  &__radio {
    display: none;

    &:checked + label::before {
      border-color: $input-border-color;
    }

    &:checked + label::after {
      transform: scale(1);
    }

    *::before,
    *::after {
      box-sizing: border-box;
    }
  }

  &__message {
    @include vr-plumbing-set($form-message-font-sizes, default);
    order: 2;
  }

  &__description {
    @include vr-plumbing-set($description-font-sizes, default);
    order: 3;
  }

  /**
   * Error state.
   */
  &--error &__input:not(:focus),
  &--error &__textarea:not(:focus) {
    border-color: $form-error-color;
  }

  &--error &__message {
    color: $form-error-color;
  }

  /**
   * Custom select dropdown.
   */

  &__select {
    height: ($input-height-base + 8px);
    padding-right: 20px;
  }
}

/**
 * Position file input better.
 */

.#{$lib}file {
  display: block;
}

/**
 * Focus for file, radio, and checkbox.
 */

.#{$lib}file:focus,
.#{$lib}audio:focus,
.#{$lib}checkbox:focus {
  @include tab-focus;
}

/**
 * Make range inputs behave like textual form controls.
 */

.#{$lib}range {
  display: block;
  height: $input-height-base;
  margin: 0;
  padding: 0;
  width: 100%;
}

/**
 * Search inputs in iOS.
 *
 * This overrides the extra rounded corners on search inputs in iOS so that our
 * `.form-control` class can properly style them. Note that this cannot simply
 * be added to `.form-control` as it's not specific enough. For details, see
 * https://github.com/twbs/bootstrap/issues/11586.
 */

.#{$lib}search {
  -webkit-appearance: none;
}

/**
 * Make multiple select elements height not fixed.
 */

.#{$lib}select[multiple],
.#{$lib}select[size] {
  height: auto;
}

/**
 * Adjust output element.
 */

output {
  color: $input-color;
  display: block;
  font-size: $font-size-base;
  line-height: $line-height-base;
  padding-top: ($padding-base-vertical + 1);
}

/**
 * Restyle and baseline non-control form elements.
 */

.#{$lib}fieldset {
  @include form-styles-reset();
}

.#{$lib}legend {
  color: $legend-color;
}

/**
 *  Password field nested icon.
 */

.#{$lib}form-item--password {
  position: relative;

  .#{$lib}form-item__input {
    box-sizing: border-box;
    padding-right: ($input-padding * 2.5);
    width: 100%;
  }

  .#{$lib}form-item__input-wrapper {
    position: relative;
  }

  .#{$lib}form-item__input-helper {
    display: flex;
    position: absolute;
    right: ($input-padding / 2);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    .#{$lib}icon {
      vertical-align: middle;
    }
  }
}
