@import 'comment.settings';

.comment-container {
  display: grid;
  grid-gap: $default-ms-col-gap;

  @include breakpoint($tablet) {
    grid-template-columns: 2fr 14fr;
  }
}
