/* stylelint-disable property-no-vendor-prefix */
/**
 * Grid repeat function
 */

@function grid-repeat($repeat, $value: 1fr) {
  $list: ();

  @for $i from 1 through $repeat {
    $list: append($list, $value, space);
  }

  @return $list;
}

/**
 * grid-column mixin
 */

@mixin grid-column($start: auto, $span: 1) {

  /** grid-column-gap using left margin */

  @if $default-ms-col-gap and not $default-ms-col-count and $start != 1 {
    margin-left: $default-ms-col-gap;

    @supports (grid-column-gap: 1px) {
      margin-left: 0;
    }
  }
  -ms-grid-column: if($default-ms-col-count, $start + $start - 1, $start);
  grid-column: #{$start}/#{$start + $span};
  -ms-grid-column-span: if($default-ms-col-count, $span + $span - 1, $span);
}

/**
 * grid-row mixin
 */

@mixin grid-row($start: auto, $span: 1) {

  /** grid-row-gap using top margin */

  @if $default-ms-row-gap and not $default-ms-row-count and $start != 1 {
    margin-top: $default-ms-row-gap;

    @supports (grid-row-gap: 1px) {
      margin-top: 0;
    }
  }
  -ms-grid-row: if($default-ms-row-count, $start + $start - 1, $start);
  grid-row: #{$start}/#{$start + $span};
  -ms-grid-row-span: if($default-ms-row-count, $span + $span - 1, $span);
}

/**
 * grid-cell mixin
 */

@mixin grid-cell($col-start: auto, $col-span: 1, $row-start: auto, $row-span: 1) {
  @include grid-column($col-start, $col-span);

  @include grid-row($row-start, $row-span);
}

/**
 * grid-area mixin
 */

@mixin grid-area($area) {
  $area: map-get($grid-areas, $area);

  @include grid-column(nth($area, 1), nth($area, 2));

  @include grid-row(nth($area, 3), nth($area, 4));
}

/**
 * grid mixin
 */

@mixin grid($grid-map) {
  @supports (display: grid) {
    $cols: map-get($grid-map, cols);
    $rows: map-get($grid-map, rows);
    $gap: map-get($grid-map, gap);
    $col-gap: map-get($grid-map, col-gap);
    $col-gap: if($col-gap, $col-gap, $gap);
    $row-gap: map-get($grid-map, row-gap);
    $row-gap: if($row-gap, $row-gap, $gap);

    /** if cols or rows are numbers convert to fraction lists */

    @if $cols and length($cols) == 1 and unitless($cols) {
      $cols: grid-repeat($cols);
    }

    @if $rows and length($rows) == 1 and unitless($rows) {
      $rows: grid-repeat($cols);
    }

    /**
     * ie does not support grid gap - why we insert the gap space as a row or colum
     * note! the first loop is to ensure cols/rows are not a multidimensional list
     * (when generated by the repeat function)
     */
    $ms-cols: null;
    $ms-rows: null;

    @if $col-gap and $cols {
      $ms-cols: ();

      @each $col in $cols {
        $ms-cols: if(type-of($col) == list, join($ms-cols, $col), append($ms-cols, $col));
      }

      @for $i from 1 through length($ms-cols) - 1 {
        $ms-cols: set-nth($ms-cols, $i, nth($ms-cols, $i) $col-gap);
      }
      //  globalize ms col count (used by grid-column)
      $default-ms-col-count: length($ms-cols) !global;
    }

    @if $row-gap and $rows {
      $ms-rows: ();

      @each $row in $rows {
        $ms-rows: if(type-of($row) == list, join($ms-rows, $row), append($ms-rows, $row));
      }

      @for $i from 1 through length($ms-rows) - 1 {
        $ms-rows: set-nth($ms-rows, $i, nth($ms-rows, $i) $row-gap);
      }

      /** globalize ms row count (used by grid-row) */
      $default-ms-row-count: length($ms-rows) !global;
    }

    $default-ms-col-gap: $col-gap !global;
    $default-ms-row-gap: $row-gap !global;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -ms-grid;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    display: grid;

    grid-column-gap: $col-gap;

    -ms-grid-columns: $ms-cols or $cols;
    grid-row-gap: $row-gap;
    -ms-grid-rows: $ms-rows or $rows;

    grid-template-columns: $cols;
    grid-template-rows: $rows;
  }
}

@mixin grid-child($col-start, $col-end, $row-start, $row-end) {
  grid-column-end: $col-end;
  grid-column-start: $col-start;
  grid-row-end: $row-end;
  grid-row-start: $row-start;
}

@supports (display: -ms-grid) {

  /* This will apply in Edge (old syntax), but not IE 10 or 11 */
}

@mixin l-constrained() {
  margin: 0 auto;
  padding-left: $default-site-gutter;
  padding-right: $default-site-gutter;
  width: 100%;

  @include breakpoint($desktop-wide) {
    max-width: $default-site-width-desktop-wide;
  }
}

/** https://rachelandrew.co.uk/css/cheatsheets/grid-fallbacks */

@mixin ie11-grid-fix($cols) {
  clear: both;

  /* IE11 solutions for grid style layout */
  > div {
    float: left;
    margin-bottom: $default-ms-col-gap;
    margin-right: $default-ms-row-gap;
    width: calc((99.99% / #{$cols}) - #{$default-ms-row-gap});

    &:nth-child(#{$cols}) {
      margin-right: 0;
    }
  }

  @supports (display: grid) {
    clear: none;

    > div {
      float: none;
      margin-bottom: initial;
      margin-right: initial;
      width: auto;

      &:nth-child(#{$cols}) {
        margin-right: initial;
      }
    }
  }
}

/**
 * Default grid mixin
 * TODO remove 2nd arg, no longer required
 */

@mixin grid-default($cols: $default-ms-col-count, $colscount: false, $row-gap: $default-ms-row-gap, $col-gap: $default-ms-col-gap) {
  $grid: ();

  @if type-of($cols) == 'number' {
    $grid: if($cols > 1,
    (
      cols: grid-repeat($cols, 1fr),
      row-gap: $row-gap,
      col-gap: $col-gap,
    ),
    (
      row-gap: $row-gap,
      col-gap: $col-gap,
    ));
  }

  @else {
    $grid: (
      cols: $cols,
      row-gap: $row-gap,
      col-gap: $col-gap,
    );
  }

  @include grid($grid);

  &::before,
  &::after {
    display: none;
  }
}

@mixin no-scroll() {
  overflow: hidden;
}
