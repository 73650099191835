@import '../loader.settings';

.#{$lib}fading-circle {
  height: $loader-size;
  margin: $loader-spinner-margin;
  position: relative;
  width: $loader-size;

  &__circle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__circle::before {
    animation: circleFadeDelay $loader-fading-circle-animation-duration infinite ease-in-out both;
    background-color: $loader-color;
    border-radius: 100%;
    content: '';
    display: block;
    height: 15%;
    margin: 0 auto;
    width: 15%;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  @for $i from 2 through $loader-fading-circle-count {
    &__circle#{$i} {
      transform: rotate(360deg / $loader-fading-circle-count * ($i - 1));
    }

    &__circle#{$i}::before {
      animation-delay: - $loader-fading-circle-animation-duration + $loader-fading-circle-animation-duration / $loader-fading-circle-count * ($i - 1);
    }
  }
}

@keyframes circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
