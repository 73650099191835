@import 'tabs.structure';

.tabs {
  .tab--label {
    background-color: $colors-primary-beta;
    border: 1px solid $colors-primary-beta;
    margin: 0;
    padding: $atom-spacing $atom-spacing 0;

    &:hover {
      cursor: pointer;
    }
  }

  .tab--input {
    @include element-invisible();
  }

  .tab--input:checked + label {
    background-color: $tab-label-active-bg-color;
    border-bottom-color: $tab-label-active-bg-color;
    color: $tab-label-active-text-color;
  }

  .tab--content {
    border: 1px solid $colors-primary-beta;
    display: none;
    margin-top: -1px;
  }

  // stylelint-disable
  #tab1:checked ~ #content1,
  #tab2:checked ~ #content2,
  #tab3:checked ~ #content3,
  #tab4:checked ~ #content4 {
    display: block;
  }
  // stylelint-enable
}
