/* Forms mixins */

@mixin base-input-style() {
  @include plumber-box(
    $margin: 1 1,
    $border: $input-border-size $input-border-size,
    $padding: 2 2,
    $grid-height: 8px
  );
  // stylelint-disable-next-line
  -webkit-appearance: none;
  background-color: $input-bg;
  border: $input-border-size $input-border-style $input-border-color;
  border-radius: $input-border-radius;
  box-sizing: content-box;
  color: $input-color;
  max-width: 100%;
  outline: none;
  padding-left: $input-padding;
  padding-right: $input-padding;
  transition: all $animation-base-duration $animation-base-ease;
  width: auto;

  &.is-disabled,
  &[disabled] {
    border-color: $input-border-disabled;
    color: $input-color-disabled;
  }
}

@mixin form-styles-reset() {
  appearance: none;
  border: 0;
  border-width: 0;
  line-height: 1;
  margin: 0;
  // stylelint-disable
  -webkit-margin-end: 0;
  -webkit-margin-start: 0;
  min-width: 0;
  padding: 0;
  -webkit-padding-after: 0;
  -webkit-padding-before: 0;
  -webkit-padding-end: 0;
  -webkit-padding-start: 0;
  // stylelint-enable
}
