@import '../loader.settings';

.#{$lib}cube-grid {
  height: $loader-size;
  margin: $loader-spinner-margin;
  width: $loader-size;

  &__cube {
    animation: cube-grid-scale-delay 1.3s infinite ease-in-out;
    background-color: $loader-color;
    float: left;
    height: 33.33%;
    width: 33.33%;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  &__cube1 {
    animation-delay: $loader-cube-grid-delay-range * 0.5;
  }

  &__cube2 {
    animation-delay: $loader-cube-grid-delay-range * 0.75;
  }

  &__cube3 {
    animation-delay: $loader-cube-grid-delay-range;
  }

  &__cube4 {
    animation-delay: $loader-cube-grid-delay-range * 0.25;
  }

  &__cube5 {
    animation-delay: $loader-cube-grid-delay-range * 0.5;
  }

  &__cube6 {
    animation-delay: $loader-cube-grid-delay-range * 0.75;
  }

  &__cube7 {
    animation-delay: 0s;
  }

  &__cube8 {
    animation-delay: $loader-cube-grid-delay-range * 0.25;
  }

  &__cube9 {
    animation-delay: $loader-cube-grid-delay-range * 0.5;
  }
}

@keyframes cube-grid-scale-delay {
  0%,
  70%,
  100% {
    transform: scale3d(1, 1, 1);
  }

  35% {
    transform: scale3d(0, 0, 1);
  }
}
