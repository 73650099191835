// ------------------------
//      Forms settings
// ------------------------
$form-bg-color: inherit !default;
$form-border-width: 0 !default;
$form-border-color: #fff !default;
$form-border-style: solid !default;
// ----------------------------------------------------------------------------
//     Base Fonts map
//     breakpoint: (font-size, line-height, leading-space, trailing-space)
// ----------------------------------------------------------------------------
$vr-forms: (
  default : (default: (16px, 2, 1, 1), tablet: (16px, 2, 1, 1), desktop: (16px, 2, 1, 1)),
  small : (default: (14px, 1, 0, 1), tablet: (15px, 1, 0, 1), desktop: (16px, 1, 0, 1)),
  labels : (default: (14px, 1, 0, 0), tablet: (15px, 1, 0, 1), desktop: (16px, 1, 0, 1))
) !default;
$form-item-bg-color: transparent !default;
$form-item-vr-margin-top: 1 !default;
$form-item-vr-margin-bottom: 1 !default;
$form-item-vr-grid-height: 8px !default;

$input-bg: #fff !default;
$input-bg-disabled: $colors-greys-beta !default;
$input-color: $text-color !default;
$input-border-size: 1px !default;
$input-border-style: solid !default;
$input-border-color: $colors-greys-gamma !default;
$input-border-radius: $border-radius-base !default;
$input-border-radius-large: $border-radius-large !default;
$input-border-radius-small: $border-radius-small !default;
$input-border-disabled: $input-border-color !default;
$input-color-disabled: rgba($input-color, 0.3) !default;
$input-border-focus: $colors-greys-epsilon !default;
$input-color-active: $text-color !default;
$input-color-focus: $text-color !default;
$input-color-placeholder: rgba($input-color, 0.2) !default;
$input-font-style-placeholder: italic !default;
$input-height-base: $font-size-base !default;
$input-padding: ($atom-spacing * 2);
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;
// ------------------------
//      Label settings
// ------------------------
$label-default-font-family: inherit !default;
$label-color: $colors-greys-alpha !default;
$label-default-font-size: $font-size-base !default;
$label-default-display: block !default;
$label-bg-color: #fff !default;
$label-spacing-left: 0 !default;
$label-spacing-right: 0 !default;
$label-text-transform: none !default;
$label-required-color: $colors-utility-gamma !default;
$label-optional-color: $colors-greys-alpha !default;
$label-optional-font-family: $label-default-font-family !default;
$label-optional-font-weight: normal !default;
// ---------------------------
//      Fieldset settings
// ---------------------------
$fieldset-border-color: $colors-secondary-gamma !default;
// ---------------------------------
//      Form animation settings
// ---------------------------------
$input-animation-property: $animation-base-property !default;
$input-animation-duration: $animation-base-duration !default;
$input-animation-ease: $animation-base-ease !default;
// -------------------------
//      Form validation
// -------------------------
$form-error-color: $colors-alert--error !default;
$form-error-font-size: $font-size-small !default;
// -----------------------
//     Base Fonts map
//     breakpoint: (font-size, line-height, leading-space, trailing-space)
// -----------------------
$form-message-font-sizes: (default: (default: ($font-size-small, 1, 0, 1))) !default;
// ----------------
//      Select
// ----------------
$select-icon-color-focus: $colors-primary-alpha !default;
$select-padding: ($atom-spacing * 2) !default;
// --------------------
//      Checkboxes
// --------------------
$checkbox-border-size: 2px !default;
$checkbox-border-radius: 2px !default;
$checkbox-focus-color: $input-color-focus !default;
$checkbox-focus-icon-color: #fff !default;
$checkbox-active-color: $input-color-active !default;
$checkbox-active-icon-color: #fff !default;
$checkbox-svg-viewbox: 0 0 24 24 !default;
$checkbox-svg-path: 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' !default;
// ----------------
//      Radios
// ----------------
$radio-fill-size: ($input-height-base / 2) !default;
$radio-border-size: 2px !default;
$radio-color-focus: $input-color-focus !default;

/**
 * Forms colors settings.
 */
$label-color: $text-color !default;

/**
 * Form states settings.
 */
$state-success-text: $colors-alert--success !default;

$label-default-bg: $colors-greys-beta !default;
$label-primary-bg: $colors-greys-beta !default;
$label-success-bg: $colors-primary-alpha !default;
$label-info-bg: $colors-secondary-alpha !default;
$label-warning-bg: $colors-secondary-alpha !default;
$label-error-bg: $colors-secondary-alpha !default;

$legend-color: $colors-primary-epsilon !default;
$legend-border-color: $colors-greys-theta !default;

$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text: $text-color !default;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-error-text: $colors-alert--error !default;
$state-error-border: darken(adjust-hue($state-error-bg, -10), 5%) !default;

$state-warning-text: $colors-alert--warning !default;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$alert-success-bg: $state-success-bg !default;
$alert-success-text: $state-success-text !default;
$alert-success-border: $state-success-border !default;

$alert-info-bg: $state-info-bg !default;
$alert-info-text: $state-info-text !default;
$alert-info-border: $state-info-border !default;

$alert-warning-bg: $state-warning-bg !default;
$alert-warning-text: $state-warning-text !default;
$alert-warning-border: $state-warning-border !default;

$alert-error-bg: $state-error-bg !default;
$alert-error-text: $state-error-text !default;
$alert-error-border: $state-error-border !default;
