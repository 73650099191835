.#{$lib}form {
  @include plumber-box(
    $margin: 0 0,
    $padding: 1 1,
    $border: 000px
  );
}

.#{$lib}fieldset {
  @include form-styles-reset();
}

.#{$lib}legend {
  @include form-styles-reset();

  @include vr-plumbing-set($vr-forms, small);
}

/**
 * HTML5 color picker.
 */

.#{$lib}form-item--input[type='color'] {
  -webkit-appearance: none;
  border: 0;
  height: 16px;
  width: 32px;
}

.#{$lib}form-item--input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

.#{$lib}form-item--input[type='color']::-webkit-color-swatch {
  border: 0;
}

.#{$lib}color {
  padding: 0;
}
