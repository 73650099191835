/**
 * Alerts
 */

$alert-padding: 15px !default;
$alert-border-radius: $border-radius-base !default;
$alert-link-font-weight: bold !default;
$alert-icon-padding: ($element-spacing * 2) !default;

/**
 * Base styles
 */
$alert: '.#{$lib}-alert';
