/**
 * Base Fonts map.
 *
 * breakpoint: (font-size, line-height, leading-space, trailing-space)
 */
$vr-fonts: (
  default: (default: (16px, 2, 0, 1), tablet: (16px, 2, 0, 1), desktop: (16px, 2, 0, 1)),
  intro: (default: (18px, 2, 0, 1), tablet: (20px, 2, 0, 1), desktop: (22px, 2, 0, 1)),
  small: (default: (12px, 1, 0, 1), tablet: (14px, 1, 0, 1), desktop: (14px, 1, 0, 1)),
  large: (default: (20px, 3, 0, 1), tablet: (20px, 3, 0, 1), desktop: (20px, 4, 0, 1))
) !default;

/**
 * Description.
 */
$description-font-sizes: (default: (default: ($font-size-base, 1, 0, 1))) !default;

/**
 * Font styles.
 */
$font-family-base: $font-family-sans-serif !default;
$font-family-italic: $font-family-sans-serif !default;
$font-family-standfirst: $font-family-sans-serif !default;
$font-family-bold: $font-family-sans-serif !default;

/**
 * Typography size settings.
 */
$font-size-large: ceil(($font-size-base * 1.25)) !default;
$font-size-small: ceil(($font-size-base * 0.85)) !default;

/**
 * Bespoke typography config.
 */
$line-height-computed: floor(($font-size-base * $line-height-base)) !default;
