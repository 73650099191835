@import '../loader.settings';

.#{$lib}double-bounce {
  height: $loader-size;
  margin: $loader-spinner-margin;
  position: relative;
  width: $loader-size;

  &__item {
    animation: doubleBounce 2s infinite ease-in-out;
    background-color: $loader-color;
    border-radius: 50%;
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  &__item2 {
    animation-delay: -1s;
  }
}

@keyframes doubleBounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
