/**
 * Hamburger
 */
.#{$lib}hamburger {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;

  // Normalize (<button>)
  font: inherit;
  height: $hamburger-height + ($hamburger-padding-y * 2);
  margin: 0;
  overflow: visible;
  padding: $hamburger-padding-y $hamburger-padding-x;
  text-transform: none;
  transition-duration: $hamburger-hover-transition-duration;

  transition-property: opacity, filter;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }

    @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &__box {
    display: block;
    height: $hamburger-height;
    position: relative;
    width: $hamburger-layer-width;
  }

  &__inner {
    display: block;
    margin-top: $hamburger-layer-height / -2;
    top: 50%;

    &,
    &::before,
    &::after {
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      height: $hamburger-layer-height;
      position: absolute;
      transition-duration: 0.15s;
      transition-property: transform;
      transition-timing-function: ease;
      width: $hamburger-layer-width;
    }

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }

  &.is-active {
    .#{$lib}hamburger__inner {
      &,
      &::before,
      &::after {
        background-color: $hamburger-layer-color-active;
      }
    }
  }
}
