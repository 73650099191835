/**
 * Helper functions.
 */

@function calc-font-ratio($font-size-pixels, $round) {
  @return $font-size-pixels / $default-grid-height;
}

/**
 * Vertical Rhythm.
 */

@mixin vr-plumbing-set($map, $key, $vr-fontfamily-baseline: $default-font-baseline) {
  $plumbing-config: map-get($map, $key);

  $type-mob: map-get($plumbing-config, default);

  $type-tab: map-get($plumbing-config, tablet);

  $type-desk: map-get($plumbing-config, desktop);

  @include vr-plumbing(calc-font-ratio(nth($type-mob, 1), 4), nth($type-mob, 2), nth($type-mob, 3), nth($type-mob, 4), $vr-fontfamily-baseline);

  @if $type-tab {
    @include breakpoint($tablet) {
      @include vr-plumbing(calc-font-ratio(nth($type-tab, 1), 4), nth($type-tab, 2), nth($type-tab, 3), nth($type-tab, 4), $vr-fontfamily-baseline);
    }
  }

  @if $type-desk {
    @include breakpoint($desktop) {
      @include vr-plumbing(calc-font-ratio(nth($type-desk, 1), 4), nth($type-desk, 2), nth($type-desk, 3), nth($type-desk, 4), $vr-fontfamily-baseline);
    }
  }
}


@mixin vr-plumbing($el-font-size, $el-line-height, $el-leading-top, $el-leading-bottom, $vr-fontfamily-baseline) {
  @include plumber(
    $grid-height: $default-grid-height,
    $baseline: $vr-fontfamily-baseline,
    $font-size: $el-font-size,
    $line-height: $el-line-height,
    $leading-top: $el-leading-top,
    $leading-bottom: $el-leading-bottom,
    $use-baseline-origin: false
  );
}

/**
 * Inverted copy.
 */

@mixin text-inverted {
  color: $text-color-inverted;
}
