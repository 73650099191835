@import 'cards.settings';

/**
 * Card structure.
 */
.#{$lib}card {
  grid-auto-rows: max-content;
  grid-row-gap: $card-row-spacing;

  &:not(&--none) {
    display: grid;
  }

  &--small {
    grid-row-gap: $card-row-spacing-small;
  }

  &--large {
    grid-row-gap: $card-row-spacing-large;
  }

  // Modifier cards.
  &--basic & {
    &__title {
      padding: 0 $element-spacing;
    }

    &__header {
      padding: 0 $element-spacing;
    }

    &__content {
      padding: 0 $element-spacing;
    }

    &__footer {
      padding: 0 $element-spacing;
    }
  }

  &--framed {
    border: 1px solid #ddd;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  }

  &--framed & {
    &__title {
      padding: 0 $element-spacing;
    }

    &__header {
      padding: 0 $element-spacing;
    }

    &__content {
      padding: 0 $element-spacing;
    }

    &__footer {
      padding: 0 $element-spacing;
    }
  }
}

.#{$lib}card-placeholder--alpha {
  background-color: $card-placeholder-color-alpha;
  height: 100%;
  margin-bottom: $default-spacing;
  width: 100%;

  &--wide {
    height: $default-spacing;
    margin-bottom: $atom-spacing;
    width: 75%;
  }

  &--narrow {
    height: $default-spacing;
    margin-bottom: $atom-spacing;
    width: 50%;
  }
}
