.#{$lib}drawer {
  animation-duration: 0.5s;
  background: $colors-primary-alpha;
  overflow-y: scroll;
  padding: $element-spacing;
  position: fixed;
  z-index: 1;
}

.#{$lib}drawer--bottom {
  bottom: 0;
  left: 0;
  width: 100%;
}

.#{$lib}drawer--top {
  left: 0;
  top: 0;
  width: 100%;
}

.#{$lib}drawer--right {
  height: 100%;
  right: 0;
  top: 0;
}

.#{$lib}drawer--left {
  height: 100%;
  left: 0;
  top: 0;
}
