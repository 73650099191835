dialog {
  background: #fff;
  border: solid;
  color: #000;
  display: block;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
._dialog_overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
