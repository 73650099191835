/**
 * Icons.
 */

@import '../buttons/buttons.settings';
@import 'material.settings';

@import './mixins/mixins.material';

.#{$lib}icon {
  @include icon-style();
}

@each $size, $value in $icon-sizes {
  .#{$lib}icon--#{$size} {
    @include icon-size($size);
  }
}
