.#{$lib}ratio {
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
  }
}

@each $width, $values in $ratios {
  @each $height in $values {
    .#{$lib}ratio--#{$width}-#{$height}::before {
      padding-top: aspect-ratio-percentage($width, $height);
    }
  }
}

.#{$lib}ratio__placeholder {
  align-content: center;
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.#{$lib}ratio__content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
