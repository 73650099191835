@mixin grid-debug {
  .sg-swatch {
    background-color: $colors-primary-alpha;
    color: $colors-primary-beta;
    padding: 1em;
  }
}

@for $i from 2 through 12 {
  .#{$lib}grid--col-#{$i} {
    @include grid-default($i);
  }
}

@for $i from 1 through 12 {
  .#{$lib}col-#{$i} {
    @include grid-area('col-#{$i}');
  }

  .#{$lib}col-#{$i}-inv {
    @include grid-area('col-#{$i}-inv');
  }
}
