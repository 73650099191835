
@import '../loader.settings';

.#{$lib}folding-cube {
  height: $loader-size;
  margin: $loader-spinner-margin;
  position: relative;
  transform: rotateZ(45deg);
  width: $loader-size;

  &__cube {
    float: left;
    height: 50%;
    position: relative;
    transform: scale(1.1);
    width: 50%;
  }

  &__cube::before {
    animation: foldCubeAngle $loader-folder-cube-animation-duration infinite linear both;
    background-color: $loader-color;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: 100% 100%;
    width: 100%;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  // Rotation / angle

  @for $i from 2 through $loader-folder-cube-count {
    &__cube#{$i} {
      transform: scale(1.1) rotateZ(90deg * ($i - 1));
    }
  }

  @for $i from 2 through $loader-folder-cube-count {
    &__cube#{$i}::before {
      animation-delay: $loader-folder-cube-delay-range / $loader-folder-cube-count * ($i - 1);
    }
  }
}

@keyframes foldCubeAngle {
  0%,
  10% {
    opacity: 0;
    transform: perspective(140px) rotateX(-180deg);
  }

  25%,
  75% {
    opacity: 1;
    transform: perspective(140px) rotateX(0deg);
  }

  90%,
  100% {
    opacity: 0;
    transform: perspective(140px) rotateY(180deg);
  }
}
