/**
 * Global device breakpoints.
 */

$mobile-only: max-width $mobile-landscape !default; // should be 1px less than $tablet
$mobile-tablet-only: max-width $below-tablet !default; // should be 1px less than $tablet

/**
 * Global constrained.
 */
$default-feature-container-size: $desktop !default;

/**
 * Global grid settings.
 */
$default-ms-col-gap: scut-rem($default-spacing) !default;
$default-ms-row-gap: scut-rem($default-spacing) !default;

/**
 * Focus scaffold
 */
$focus-default-color: transparent;
$focus-default-style: solid;
$focus-default-width: 0;
