@import '../loader.settings';

.#{$lib}waves {
  font-size: 10px;
  height: $loader-size;
  margin: $loader-spinner-margin;
  text-align: center;
  width: $loader-size * 1.25;

  &__wave {
    animation: waveStretchDelay $loader-waves-animation-duration infinite ease-in-out;
    background-color: $loader-color;
    display: inline-block;
    height: 100%;
    margin-left: 2px;
    margin-right: 2px;
    width: 6px;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  @for $i from 1 through $loader-waves-count {
    &__wave#{$i} {
      animation-delay: - $loader-waves-animation-duration + $loader-waves-delay-range / ($loader-waves-count - 1) * ($i - 1);
    }
  }
}

@keyframes waveStretchDelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}
