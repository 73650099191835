@import 'alerts.structure';

#{$alert} {
  &--block {
    border: 1px solid transparent;
    border-radius: $alert-border-radius;
  }

  &__title {
    color: inherit;
    font-family: $font-family-bold;
    font-weight: bold;
  }

  &--success {
    background-color: $alert-success-bg;
    border-color: $alert-success-border;
    color: $alert-success-text;

    &#{$alert}--inverted {
      background-color: $alert-success-text;
      border-color: $alert-success-border;
      color: $alert-success-bg;
    }
  }

  &--info {
    background-color: $alert-info-bg;
    border-color: $alert-info-border;
    color: $alert-info-text;

    &#{$alert}--inverted {
      background-color: $alert-info-text;
      border-color: $alert-info-border;
      color: $alert-info-bg;
    }
  }

  &--warning {
    background-color: $alert-warning-bg;
    border-color: $alert-warning-border;
    color: $alert-warning-text;

    &#{$alert}--inverted {
      background-color: $alert-warning-text;
      border-color: $alert-warning-border;
      color: $alert-warning-bg;
    }
  }

  &--error {
    background-color: $alert-error-bg;
    border-color: $alert-error-border;
    color: $alert-error-text;

    &#{$alert}--inverted {
      background-color: $alert-error-text;
      border-color: $alert-error-border;
      color: $alert-error-bg;
    }
  }
}
