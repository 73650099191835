@import '../loader.settings';

.#{$lib}spinner--pulse {
  animation: pulseScaleOut 1s infinite ease-in-out;
  background-color: $loader-color;
  border-radius: 100%;
  height: $loader-size;
  margin: $loader-spinner-margin;
  width: $loader-size;

  .#{$lib}inverted & {
    background-color: $loader-color-inverted;
  }
}

@keyframes pulseScaleOut {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}
