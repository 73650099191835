// -------------------------
//      Loader settings
// -------------------------
$loader-default-width: 2rem !default;
$loader-default-height: 2rem !default;
$loader-default-font-size: 1rem !default;

$loader-animation-default-keyframe: loader !default;
$loader-animation-default-duration: 0.6s !default;
$loader-animation-default-ease: linear !default;

$loader-color: $text-color !default;
$loader-color-inverted: $text-color-inverted !default;

$loader-animation-default-iteration-count: infinite !default;
$loader-animation-border-color: $loader-color transparent transparent !default;
$loader-animation-border-color-inverted: $loader-color-inverted transparent transparent !default;
$loader-animation-border-style: solid !default;
$loader-animation-border-width: 0.2em !default;
$loader-animation-box-shadow: 0 0 0 1px transparent !default;

$loader-default-border-bg: 0.2em solid rgba(0, 0, 0, 0.1) !default;

$loader-spinner-margin: auto !default;
$loader-size: 40px !default;

// ------------------------
//     Chasing circles
// ------------------------
$loader-chasing-circles-animation-duration: 1.2s !default;
$loader-chasing-circles-count: 12 !default;

// ---------------------
//     Chasing dots
// ---------------------
$loader-chasing-dots-animation-duration: 2s !default;

// ------------------
//     Cube grid
// ------------------
$loader-cube-grid-delay-range: 0.4s !default;

// ----------------------
//     Fading circle
// ----------------------
$loader-fading-circle-count: 12 !default;
$loader-fading-circle-animation-duration: 1.2s !default;

// ---------------------
//     Folding cube
// ---------------------
$loader-folder-cube-count: 4 !default;
$loader-folder-cube-animation-duration: 2.4s !default;
$loader-folder-cube-delay-range: ($loader-folder-cube-animation-duration / 2) !default;

// ---------------------
//     Three bounce
// ---------------------
$loader-three-bounce-animation-duration: 1.4s !default;
$loader-three-bounce-delay-range: 0.32s !default;

// ------------------------
//     Wandering cubes
// ------------------------
$loader-wandering-cubes-animation-duration: 1.8s !default;

// --------------
//     Waves
// --------------
$loader-waves-count: 5 !default;
$loader-waves-animation-duration: 1.2s !default;
$loader-waves-delay-range: 0.4s !default;
