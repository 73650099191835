/**
 * Typographic & Grid baseline.
 */
$default-grid-height: 16px !default;

/**
 * Global Spacing
 * Should be related to typographic baseline $default-grid-height.
 */
$default-spacing: 16px !default;
$atom-spacing: (16px / 2) !default;
$element-spacing: (16px * 1.5) !default;
$component-spacing: (16px * 3) !default;
$module-spacing: (16px * 4) !default;
$feature-spacing: (16px * 6) !default;

$default-site-gutter: 16px !default;

/**
 * Global device breakpoints.
 */
$mobile: 0 !default;
$mobile-portrait: 20em !default;
$below-mobile-portrait: 19.938em !default;
$mobile-landscape: 30em !default;
$below-mobile-landscape: 29.938em !default;
$tablet-narrow-below: 39.938em !default;
$tablet-narrow: 40em !default;
$below-tablet: 47.938em !default;
$tablet: 48em !default;
$tablet-landscape: 64em !default;
$below-desktop: 79.938em !default;
$desktop-narrow: 60em !default;
$desktop: 80em !default;
$desktop-wide: 100em !default;
$below-desktop-wide: 99.188em !default;

/**
 * Global constrained.
 */
$default-site-width: 1440px !default;
$default-site-width-tablet: 730px !default;
$default-site-width-desktop: 1024px !default;
$default-site-width-desktop-wide: 1363px !default;

/**
 * Global grid settings.
 */
$default-ms-col-count: 16 !default;
$default-ms-row-count: 4 !default;

/**
 * Grid area map (col, col-span, row, row-span).
 */
$grid-areas: (
  default: (12, 1, 1, 1),
  col-1: (1, 1, 1, 1),
  col-2: (1, 2, 2, 1),
  col-3: (1, 3, 1, 1),
  col-4: (1, 4, 1, 1),
  col-5: (1, 5, 1, 1),
  col-6: (1, 6, 1, 1),
  col-7: (1, 7, 1, 1),
  col-8: (1, 8, 1, 1),
  col-9: (1, 9, 1, 1),
  col-10: (1, 10, 1, 1),
  col-11: (1, 11, 1, 1),
  col-12: (1, 12, 1, 1),
  col-1-inv: (12, 1, 1, 1),
  col-2-inv: (11, 2, 1, 1),
  col-3-inv: (10, 3, 1, 1),
  col-4-inv: (9, 4, 1, 1),
  col-5-inv: (8, 5, 1, 1),
  col-6-inv: (7, 6, 1, 1),
  col-7-inv: (6, 7, 1, 1),
  col-8-inv: (5, 8, 1, 1),
  col-9-inv: (4, 9, 1, 1),
  col-10-inv: (3, 10, 2, 1),
  col-11-inv: (2, 11, 1, 1),
  col-12-inv: (1, 12, 1, 1)
) !default;
