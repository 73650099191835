/**
 * Headings structure.
 */

.h1 {
  @include vr-plumbing-set($vr-headings, h1, $default-heading-font-baseline);
}

.h2 {
  @include vr-plumbing-set($vr-headings, h2, $default-heading-font-baseline);
}

.h3 {
  @include vr-plumbing-set($vr-headings, h3, $default-heading-font-baseline);
}

.h4 {
  @include vr-plumbing-set($vr-headings, h4, $default-heading-font-baseline);
}

.h5 {
  @include vr-plumbing-set($vr-headings, h5, $default-heading-font-baseline);
}

.h6 {
  @include vr-plumbing-set($vr-headings, h6, $default-heading-font-baseline);
}

.display-h1 {
  @include vr-plumbing-set($vr-display-headings, h1, $default-heading-font-baseline);
}

.display-h2 {
  @include vr-plumbing-set($vr-display-headings, h2, $default-heading-font-baseline);
}

.display-h3 {
  @include vr-plumbing-set($vr-display-headings, h3, $default-heading-font-baseline);
}

.display-h4 {
  @include vr-plumbing-set($vr-display-headings, h4, $default-heading-font-baseline);
}

.display-h5 {
  @include vr-plumbing-set($vr-display-headings, h5, $default-heading-font-baseline);
}

.display-h6 {
  @include vr-plumbing-set($vr-display-headings, h6, $default-heading-font-baseline);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @include heading-style-default();

  i,
  em {
    @include heading-style-italic();
  }

  &.#{$lib}inverted,
  .#{$lib}inverted & {
    @include text-inverted;
  }
}

.display-h1,
.display-h2,
.display-h3,
.display-h4,
.display-h5,
.display-h6 {
  @include heading-style-display();

  i,
  em {
    @include heading-style-display-italic();
  }

  &.#{$lib}inverted,
  .#{$lib}inverted & {
    @include text-inverted;
  }
}
