// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.invisible,
.is-hidden {
  @include element-invisible();
  visibility: hidden;
}

.text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  text-shadow: none;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.invisible {
  visibility: hidden;
}

.sr-only,
.sr-only-focusable {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
