/**
 * Gradient color stops.
 */
$gradient-ui-alpha-colors: $colors-primary-alpha, $colors-secondary-alpha !default;
$gradient-ui-beta-colors: $colors-primary-beta, $colors-secondary-beta !default;
$gradient-ui-gamma-colors: $colors-primary-gamma, $colors-secondary-gamma !default;
$gradient-ui-delta-colors: $colors-primary-delta, $colors-secondary-delta !default;
$gradient-ui-epsilon-colors: $colors-primary-epsilon, $colors-secondary-epsilon !default;
$gradient-ui-zeta-colors: $colors-primary-zeta, $colors-secondary-zeta !default;
$gradient-ui-eta-colors: $colors-primary-eta, $colors-secondary-eta !default;
$gradient-ui-theta-colors: $colors-primary-theta, $colors-secondary-theta !default;
$gradient-ui-iota-colors: $colors-primary-alpha, $colors-secondary-alpha !default;
$gradient-ui-kappa-colors: $colors-primary-beta, $colors-secondary-beta !default;
$gradient-ui-lambda-colors: $colors-primary-gamma, $colors-secondary-gamma !default;
$gradient-ui-mu-colors: $colors-primary-delta, $colors-secondary-delta !default;
$gradient-ui-nu-colors: $colors-primary-epsilon, $colors-secondary-epsilon !default;
$gradient-ui-xi-colors: $colors-primary-zeta, $colors-secondary-zeta !default;
$gradient-ui-omicron-colors: $colors-primary-eta, $colors-secondary-eta !default;
$gradient-ui-pi-colors: $colors-primary-theta, $colors-secondary-theta !default;

/**
  * Gradients setup.
  */
$gradient-ui-alpha: linear-gradient(to bottom, $colors-primary-alpha, $colors-secondary-alpha) !default;
$gradient-ui-beta: linear-gradient(to bottom, $colors-primary-beta, $colors-secondary-beta) !default;
$gradient-ui-gamma: linear-gradient(to bottom, $colors-primary-gamma, $colors-secondary-gamma) !default;
$gradient-ui-delta: linear-gradient(to bottom, $colors-primary-delta, $colors-secondary-delta) !default;
$gradient-ui-epsilon: linear-gradient(to bottom, $colors-primary-epsilon, $colors-secondary-epsilon) !default;
$gradient-ui-zeta: linear-gradient(to bottom, $colors-primary-zeta, $colors-secondary-zeta) !default;
$gradient-ui-eta: linear-gradient(to bottom, $colors-primary-eta, $colors-secondary-eta) !default;
$gradient-ui-theta: linear-gradient(to bottom, $colors-primary-theta, $colors-secondary-theta) !default;
$gradient-ui-iota: linear-gradient(to bottom, $colors-primary-iota, $colors-secondary-iota) !default;
$gradient-ui-kappa: linear-gradient(to bottom, $colors-primary-kappa, $colors-secondary-kappa) !default;
$gradient-ui-lambda: linear-gradient(to bottom, $colors-primary-lambda, $colors-secondary-lambda) !default;
$gradient-ui-mu: linear-gradient(to bottom, $colors-primary-mu, $colors-secondary-mu) !default;
$gradient-ui-nu: linear-gradient(to bottom, $colors-primary-nu, $colors-secondary-nu) !default;
$gradient-ui-xi: linear-gradient(to bottom, $colors-primary-xi, $colors-secondary-xi) !default;
$gradient-ui-omicron: linear-gradient(to bottom, $colors-primary-omicron, $colors-secondary-omicron) !default;
$gradient-ui-pi: linear-gradient(to bottom, $colors-primary-pi, $colors-secondary-pi) !default;

$gradient-ui-secondary-alpha: linear-gradient(to bottom, $colors-primary-alpha, $colors-secondary-alpha) !default;
$gradient-ui-secondary-beta: linear-gradient(to bottom, $colors-primary-beta, $colors-secondary-beta) !default;
$gradient-ui-secondary-gamma: linear-gradient(to bottom, $colors-primary-gamma, $colors-secondary-gamma) !default;
$gradient-ui-secondary-delta: linear-gradient(to bottom, $colors-primary-delta, $colors-secondary-delta) !default;
$gradient-ui-secondary-epsilon: linear-gradient(to bottom, $colors-primary-epsilon, $colors-secondary-epsilon) !default;
$gradient-ui-secondary-zeta: linear-gradient(to bottom, $colors-primary-zeta, $colors-secondary-zeta) !default;
$gradient-ui-secondary-eta: linear-gradient(to bottom, $colors-primary-eta, $colors-secondary-eta) !default;
$gradient-ui-secondary-theta: linear-gradient(to bottom, $colors-primary-theta, $colors-secondary-theta) !default;
$gradient-ui-secondary-iota: linear-gradient(to bottom, $colors-primary-iota, $colors-secondary-iota) !default;
$gradient-ui-secondary-kappa: linear-gradient(to bottom, $colors-primary-kappa, $colors-secondary-kappa) !default;
$gradient-ui-secondary-lambda: linear-gradient(to bottom, $colors-primary-lambda, $colors-secondary-lambda) !default;
$gradient-ui-secondary-mu: linear-gradient(to bottom, $colors-primary-mu, $colors-secondary-mu) !default;
$gradient-ui-secondary-nu: linear-gradient(to bottom, $colors-primary-nu, $colors-secondary-nu) !default;
$gradient-ui-secondary-xi: linear-gradient(to bottom, $colors-primary-xi, $colors-secondary-xi) !default;
$gradient-ui-secondary-omicron: linear-gradient(to bottom, $colors-primary-omicron, $colors-secondary-omicron) !default;
$gradient-ui-secondary-pi: linear-gradient(to bottom, $colors-primary-pi, $colors-secondary-pi) !default;
