// Hide elements from all users.//
// Used for elements which should not be immediately displayed to any user. An
// example would be a collapsible fieldset that will be expanded with a click
// from a user. The effect of this class can be toggled with the jQuery show()
// and hide() functions.

@mixin element-hidden {
  display: none;
}

// Hide elements visually, but keep them available for screen-readers.
//
// Used for information required for screen-reader users to understand and use
// the site where visual display is undesirable. Information provided in this
// manner should be kept concise, to avoid unnecessary burden on the user.
// "!important" is used to prevent unintentional overrides.

@mixin element-invisible {
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
}
// Reverts 'element-invisible'.

@mixin element-visible {
  clip: inherit;
  height: inherit;
  overflow: inherit;
  position: inherit;
}

// The .element-focusable class extends the .element-invisible class to allow
// the element to be focusable when navigated to via the keyboard.

@mixin element-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
  }
}

// Visually hidden but maintains layout/height.

@mixin element-opaque {
  visibility: hidden;
}
