@if index($hamburger-types, boring) {

  /*
   * Boring
   */
  .#{$lib}hamburger--boring {
    .#{$lib}hamburger__inner {
      &,
      &::before,
      &::after {
        transition-property: none;
      }
    }

    &.is-active {
      .#{$lib}hamburger__inner {
        transform: rotate(45deg);

        &::before {
          opacity: 0;
          top: 0;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
