@import '../loader.settings';

.#{$lib}chasing-circles {
  height: $loader-size;
  margin: $loader-spinner-margin;
  position: relative;
  width: $loader-size;

  &__circle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__circle::before {
    animation: circle-bounce-delay $loader-chasing-circles-animation-duration infinite ease-in-out both;
    background-color: $loader-color;
    border-radius: 100%;
    content: '';
    display: block;
    height: 15%;
    margin: 0 auto;
    width: 15%;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  @for $i from 2 through $loader-chasing-circles-count {
    &__circle#{$i} {
      transform: rotate(360deg / $loader-chasing-circles-count * ($i - 1));
    }

    &__circle#{$i}::before {
      animation-delay: - $loader-chasing-circles-animation-duration + $loader-chasing-circles-animation-duration / $loader-chasing-circles-count * ($i - 1);
    }
  }
}

@keyframes circle-bounce-delay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
