@import '../loader.settings';

.#{$lib}rotating-plane {
  animation: rotatePlane 1.2s infinite ease-in-out;
  background-color: $loader-color;
  height: $loader-size;
  margin: $loader-spinner-margin;
  width: $loader-size;

  .#{$lib}inverted & {
    background-color: $loader-color-inverted;
  }
}

@keyframes rotatePlane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
