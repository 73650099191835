// Calculate aspect ratio as percentage
// 16:9 `aspect-ratio-percentage(16, 9)`
// -------------------------------------

@function aspect-ratio-percentage($width, $height) {
  $amount: (100% / $width) * $height;

  @return $amount;
}

// Screen reader only
// ------------------

@mixin screen-reader-only() {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Screen reader undo
// ------------------

@mixin screen-reader-undo() {
  clip: inherit;
  height: inherit;
  margin: inherit;
  overflow: inherit;
  position: inherit;
  width: inherit;
}

// Color a SVG
// -----------

@mixin svg-color($color) {
  path {
    fill: $color;
  }
}

// CSS Triangle
// http://apps.eky.hk/css-triangle-generator/
// ------------

@mixin triangle($size, $color) {
  border-color: transparent transparent $color transparent;
  border-style: solid;
  border-width: 0 $size $size $size;
  height: 0;
  width: 0;
}
