/**
 * Forms colors.
 */
$label-link-hover-color: #fff !default;

/**
 * Form states.
 */
$state-success-bg: #dff0d8 !default;
$state-info-bg: #d9edf7 !default;
$state-warning-bg: #fcf8e3 !default;
$state-error-bg: #f2dede !default;
