// --------------------------------------
//      PlumberSass vertical rhythm
// --------------------------------------

// @include plumber-set-defaults(
//   $grid-height: $default-grid-height,
//   $baseline: $default-font-baseline,
//   $font-size: 2,
//   $line-height: $line-height-base,
//   $leading-top: 1,
//   $leading-bottom: 1,
//   $use-baseline-origin: false
// );

// @include plumber-set-defaults(
//   $grid-height: $default-grid-height,
//   $baseline: $default-font-baseline,
//   $font-size: $font-size-base-int,
//   $line-height: $line-height-base,
//   $leading-top: 1,
//   $leading-bottom: 1,
//   $use-baseline-origin: false
// );

p {
  @include vr-plumbing-set($vr-fonts, default);
}

.label-heading {
  text-transform: uppercase;
}

.#{$lib}description {
  @include vr-plumbing-set($description-font-sizes, default);
}

/**
 * Copy.
 *
 * 1. Ensures copy sits flush within a container.
 */
.#{$lib}copy {
  // stylelint-disable-next-line selector-no-vendor-prefix
  ::-moz-selection {
    background-color: $colors-primary-alpha;
    color: $colors-utility-white;
  }

  ::selection {
    background-color: $colors-primary-alpha;
    color: $colors-utility-white;
  }

  > *:nth-last-child(1) {
    margin-bottom: 0; // 1.
  }
}

/**
 * Maximum copy length.
 *
 * Used to retain readibility.
 */
.#{$lib}copy--max {
  max-width: $copy-max-length;
}

/**
 * Inverted copy.
 */
.#{$lib}copy--inverted,
.#{$lib}inverted .#{$lib}copy {
  @include text-inverted;
}

/**
 * Sizes.
 */
.#{$lib}text {
  @include vr-plumbing-set($vr-fonts, default);
}

.#{$lib}text--small {
  @include vr-plumbing-set($vr-fonts, small);
}

.#{$lib}text--large {
  @include vr-plumbing-set($vr-fonts, large);
}

.#{$lib}text--intro {
  @include vr-plumbing-set($vr-fonts, intro);
}

/**
 * Alignment.
 */

.#{$lib}text--left {
  text-align: left;
}

.#{$lib}text--right {
  text-align: right;
}

.#{$lib}text--center {
  text-align: center;
}

.#{$lib}text--justify {
  text-align: justify;
}

.#{$lib}text--nowrap {
  white-space: nowrap;
}

/**
 * Transformation.
 */

.#{$lib}text--lowercase {
  text-transform: lowercase;
}

.#{$lib}text--uppercase {
  text-transform: uppercase;
}

.#{$lib}text--capitalize {
  text-transform: capitalize;
}

/**
 * Contextual colors.
 */

.#{$lib}text--inverted {
  color: $text-color-inverted;
}

.#{$lib}text--muted {
  color: $text-muted;
}
