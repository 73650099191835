body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
 * Sticky footer.
 * 1. https://gist.github.com/palicko/26b327cd2198e52c25b13485b4dd02bc
 */
 .root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 100vh;

  @media all
    and (device-width: 768px)
    and (device-height: 1024px)
    and (orientation: portrait) {
    min-height: 1024px;
  }

  @media all
    and (device-width: 768px)
    and (device-height: 1024px)
    and (orientation: landscape) {
    min-height: 768px;
  }

  @media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
    min-height: 635px;
  }

  @media only screen
    and (device-width: 414px)
    and (device-height: 736px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
    min-height: 621px;
  }

  @media only screen
    and (device-width: 375px)
    and (device-height: 667px)
    and (-webkit-device-pixel-ratio: 2)
    and (orientation: portrait) {
    min-height: 553px;
  }

  @media screen and (device-aspect-ratio: 40 / 71) {
    min-height: 500px;
  }

  @media screen
    and (device-width: 320px)
    and (device-aspect-ratio: 2 / 3)
    and (orientation: portrait) {
    min-height: 480px;
  }
}

.root-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}
