// -----------------------
//     Helper classes
// -----------------------

.sr-only {
  @include screen-reader-only();
}

// Floats.

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

// Center.
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Clearfix.
.clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}
