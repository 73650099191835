$icon-sizes: (
  default: $font-size-base,
  small: 12px,
  large: 24px,
  xlarge: 48px,
  button: $btn-font-size-base,
  button-small: $btn-font-size-small,
  button-large: $btn-font-size-large,
) !default;

$icon-colors: (
  default: $text-color
) !default;
