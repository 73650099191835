// ---------------------
//      Nav Primary
// ---------------------

@import 'nav-primary.structure';

.nav-list {
  list-style: none;

  a {
    background-color: $colors-primary-beta;
    display: block;
    padding: $atom-spacing ($atom-spacing * 2);
    text-align: center;
  }
}
