/**
 * Scaffolding
 */

/* Reset the box-sizing */

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

/* Body reset */

html {
  font-size: $font-size-base;
  min-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background: $default-body-bg-color;
  color: $text-color;
  font-family: $font-family-base;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

*:focus {
  outline-color: $focus-default-color;
  outline-style: $focus-default-style;
  outline-width: $focus-default-width;
}

/* Reset fonts for relevant elements */

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  background-color: transparent;
  border: 0;
}

/**
 * Links.
 */
.#{$lib}link {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

/**
 * Unstyled link.
 */
.#{$lib}link--unstyled {
  &,
  &:hover,
  &:focus {
    color: inherit;
  }
}

/**
 * Inverted link.
 */
.#{$lib}link--inverted {
  color: $link-color-inverted;

  &:hover,
  &:focus {
    color: $link-color-inverted-hover;
  }
}

/**
 * Figures.
 */

//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
  margin: 0;
}

// Images

img,
.#{$lib}image {
  height: auto;
  vertical-align: middle;
}

// The image will take up the entire width of the container.
.#{$lib}image--fluid {
  width: 100%;
}

// Responsive images (ensure images don't scale beyond their parents)

.img-responsive {
  @include img-responsive;
}

// Rounded corners

.img-rounded {
  border-radius: $border-radius-large;
}

// Perfect circle

.img-circle {
  border-radius: 50%; // set radius in percents
}

// Horizontal rules

hr {
  @include plumber-box(
    $margin: 0 1,
    $border: 0 1px,
    $padding: 0 1,
    $grid-height: 16px
  );
  border: 0;
  border-bottom: 1px solid $colors-greys-alpha;
  height: 0;
  margin: 0;
}

// Lists

.inline-list {
  display: inline-flex;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role='button'] {
  cursor: pointer;
}

// sup & sub
sup,
sub {
  position: relative;
  top: -0.4em;
  vertical-align: baseline;
}

sub {
  top: 0.4em;
}

// visibility
%element-invisible {
  @include element-invisible;
}

.visually-hidden {
  @include element-invisible;
}

%element-focusable {
  @include element-focusable;
}

.element-invisible {
  @include element-invisible;
}

%element-hidden,
.element-hidden {
  @include element-hidden;
}

%element-opaque,
.element-opaque {
  @include element-opaque;
}
