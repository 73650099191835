// ------------------------------
//      Nav Footer structure
// ------------------------------

@import 'nav-footer.settings';

.nav-footer {
  margin: 0;
  padding: 0;

  @include breakpoint($tablet) {
    display: inline-flex;
  }
}
