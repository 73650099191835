// --------------
//      Main
// --------------
$default-body-bg-color: #fff !default;
$default-content-bg-color: #ddd !default;

// ------------------------------
//      Primary colors
// ------------------------------
$colors-primary-alpha: #999 !default;
$colors-primary-beta: #f8f7f7 !default;
$colors-primary-gamma: #c4c4c4 !default;
$colors-primary-delta: #6c6c6c !default;
$colors-primary-epsilon: #333 !default;
$colors-primary-zeta: #b1b1b1 !default;
$colors-primary-eta: #848383 !default;
$colors-primary-theta: #ddd !default;
$colors-primary-iota: #aaa !default;
$colors-primary-kappa: #aaa !default;
$colors-primary-lambda: #aaa !default;
$colors-primary-mu: #aaa !default;
$colors-primary-nu: #aaa !default;
$colors-primary-xi: #aaa !default;
$colors-primary-omicron: #aaa !default;
$colors-primary-pi: #aaa !default;

// --------------------------------
//      Secondary colors
// --------------------------------
$colors-secondary-alpha: #555 !default;
$colors-secondary-beta: #eee !default;
$colors-secondary-gamma: #aaa !default;
$colors-secondary-delta: #333 !default;
$colors-secondary-epsilon: #111 !default;
$colors-secondary-zeta: #888 !default;
$colors-secondary-eta: #555 !default;
$colors-secondary-theta: #aaa !default;
$colors-secondary-iota: #aaa !default;
$colors-secondary-kappa: #aaa !default;
$colors-secondary-lambda: #aaa !default;
$colors-secondary-mu: #aaa !default;
$colors-secondary-nu: #aaa !default;
$colors-secondary-xi: #aaa !default;
$colors-secondary-omicron: #aaa !default;
$colors-secondary-pi: #aaa !default;

// --------------------------------
//      Tertiary colors
// --------------------------------
$colors-tertiary-alpha: #999 !default;
$colors-tertiary-beta: #f8f7f7 !default;
$colors-tertiary-gamma: #c4c4c4 !default;
$colors-tertiary-delta: #6c6c6c !default;
$colors-tertiary-epsilon: #333 !default;
$colors-tertiary-zeta: #b1b1b1 !default;
$colors-tertiary-eta: #848383 !default;
$colors-tertiary-theta: #ddd !default;
$colors-tertiary-iota: #aaa !default;
$colors-tertiary-kappa: #aaa !default;
$colors-tertiary-lambda: #aaa !default;
$colors-tertiary-mu: #aaa !default;
$colors-tertiary-nu: #aaa !default;
$colors-tertiary-xi: #aaa !default;
$colors-tertiary-omicron: #aaa !default;
$colors-tertiary-pi: #aaa !default;

// --------------------------------
//      UI colors
// --------------------------------
$colors-ui-alpha: #999 !default;
$colors-ui-beta: #f8f7f7 !default;
$colors-ui-gamma: #c4c4c4 !default;
$colors-ui-delta: #6c6c6c !default;
$colors-ui-epsilon: #333 !default;
$colors-ui-zeta: #b1b1b1 !default;
$colors-ui-eta: #848383 !default;
$colors-ui-theta: #ddd !default;
$colors-ui-iota: #aaa !default;
$colors-ui-kappa: #aaa !default;
$colors-ui-lambda: #aaa !default;
$colors-ui-mu: #aaa !default;
$colors-ui-nu: #aaa !default;
$colors-ui-xi: #aaa !default;
$colors-ui-omicron: #aaa !default;
$colors-ui-pi: #aaa !default;

/**
 * Utility colors.
 */

$colors-utility-black: #000 !default;
$colors-utility-white: #fff !default;

$colors-utility-alpha: #c1e293 !default;
$colors-utility-beta: #ffd379 !default;
$colors-utility-gamma: #e5767c !default;
$colors-utility-delta: #6c6c6c !default;
$colors-utility-epsilon: #333 !default;
$colors-utility-zeta: #b1b1b1 !default;
$colors-utility-eta: #848383 !default;
$colors-utility-theta: #ddd !default;
$colors-utility-iota: #aaa !default;
$colors-utility-kappa: #aaa !default;
$colors-utility-lambda: #aaa !default;
$colors-utility-mu: #aaa !default;
$colors-utility-nu: #aaa !default;
$colors-utility-xi: #aaa !default;
$colors-utility-omicron: #aaa !default;
$colors-utility-pi: #aaa !default;

/**
 * Greys.
 */

$colors-greys-alpha: #999 !default;
$colors-greys-beta: #f8f7f7 !default;
$colors-greys-gamma: #c4c4c4 !default;
$colors-greys-delta: #6c6c6c !default;
$colors-greys-epsilon: #333 !default;
$colors-greys-zeta: #b1b1b1 !default;
$colors-greys-eta: #848383 !default;
$colors-greys-theta: #ddd !default;
$colors-greys-iota: #aaa !default;
$colors-greys-kappa: #aaa !default;
$colors-greys-lambda: #aaa !default;
$colors-greys-mu: #aaa !default;
$colors-greys-nu: #aaa !default;
$colors-greys-xi: #aaa !default;
$colors-greys-omicron: #aaa !default;
$colors-greys-pi: #aaa !default;

/**
 * Text.
 */

$text-color: #000 !default;
$text-color-inverted: #fff !default;

/**
 * Brand.
 */

$brand-cta-color: #ff69b4 !default;

/**
 * Social.
 */
$google-red: #db3236;
$facebook-blue: #3b5998;
$twitter-blue: #1da1f2;
