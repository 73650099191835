@import 'modal.settings';

/**
 * Modal.
 */
.#{$lib}modal {
  background-color: $modal-background-color;
  overflow: auto;
  padding: $modal-padding;
  z-index: $zindex-modal;

  &::backdrop,
  // For polyfill.
  + .backdrop {
    background-color: $modal-backdrop-color;
  }
}

/**
 * Default modifier.
 *
 * 1. The maximum height the modal will be before it scrolls the contents inside it.
 */
.#{$lib}modal--default {
  $total-gutter-size: ($modal-default-width-gutter * 2);
  border: $modal-default-border-style;
  border-radius: $modal-default-border-radius;
  width: 100%;

  @include breakpoint(0 $below-tablet) {
    border-radius: $modal-default-mobile-border-radius;
    height: $modal-default-mobile-height;
  }

  @include breakpoint($tablet) {
    max-height: $modal-default-max-height; // 1.
  }

  @include breakpoint(($modal-default-max-width + $total-gutter-size)) {
    max-width: $modal-default-max-width;
  }
}

/**
 * Fullscreen modifier.
 *
 * 1. Required for `dialog-polyfill` that sets an inline `top` amount.
 */
.#{$lib}modal--fullscreen {
  border: $modal-fullscreen-border-style;
  border-radius: $modal-fullscreen-border-radius;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  // stylelint-disable-next-line declaration-no-important
  top: 0 !important; // 1.
  width: 100%;
}
