@import '../loader.settings';

.#{$lib}chasing-dots {
  animation: chasingDotsRotate $loader-chasing-dots-animation-duration infinite linear;
  height: $loader-size;
  margin: $loader-spinner-margin;
  position: relative;
  text-align: center;
  width: $loader-size;

  &__dot {
    animation: chasingDotsBounce $loader-chasing-dots-animation-duration infinite ease-in-out;
    background-color: $loader-color;
    border-radius: 100%;
    display: inline-block;
    height: 60%;
    position: absolute;
    top: 0;
    width: 60%;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  &__dot2 {
    animation-delay: - ($loader-chasing-dots-animation-duration / 2);
    bottom: 0;
    top: auto;
  }
}

@keyframes chasingDotsRotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes chasingDotsBounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
