/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

@media print {
  *,
  *::before,
  *::after {
    background: transparent;
    box-shadow: none;
    color: #000; // Black prints faster: h5bp.com/s
    text-shadow: none;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  [href]::after {
    content: ' (' attr(href) ')';
  }

  [title]::after {
    content: ' (' attr(title) ')';
  }

  [href^='#']::after,
  [href^='javascript:']::after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100%;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
