$nbcu-sass-asset-helper: false !default;

// ------------------
//      Headings
// ------------------
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

// --------------------
//      Components
// --------------------
$padding-small-vertical: 8px !default;
$padding-small-horizontal: 8px !default;
$padding-base-vertical: 16px !default;
$padding-base-horizontal: 16px !default;
$padding-large-vertical: 24px !default;
$padding-large-horizontal: 24px !default;
$padding-xs-vertical: 2px !default;
$padding-xs-horizontal: 2px !default;

$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;

$border-radius-base: 2px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

$component-active-color: #fff !default;
$caret-width-base: 4px !default;
$caret-width-large: 5px !default;

// ----------------
//      Tables
// ----------------
$table-cell-padding: 16px !default;
$table-condensed-cell-padding: 8px !default;
$table-bg: transparent !default;
$table-bg-accent: #f9f9f9 !default;
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;
$table-border-color: #ddd !default;

// --------------------
//      Pagination
// --------------------
$pagination-color: $link-color !default;
$pagination-bg: #fff !default;
$pagination-border: #ddd !default;

$pagination-hover-color: $link-color !default;
$pagination-hover-bg: $colors-greys-alpha !default;
$pagination-hover-border: #ddd !default;

$pagination-active-color: #fff !default;
$pagination-active-bg: $colors-primary-beta !default;
$pagination-active-border: $colors-primary-alpha !default;

$pagination-disabled-color: $colors-greys-beta !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: #ddd !default;

// Pager
//

$pager-bg: $pagination-bg !default;
$pager-border: $pagination-border !default;
$pager-border-radius: 16px !default;
$pager-hover-bg: $pagination-hover-bg !default;
$pager-active-bg: $pagination-active-bg !default;
$pager-active-color: $pagination-active-color !default;
$pager-disabled-color: $pagination-disabled-color !default;

// Breadcrumbs
//

$breadcrumb-padding-vertical: 8px !default;
$breadcrumb-padding-horizontal: 16px !default;
$breadcrumb-bg: #f5f5f5 !default;
$breadcrumb-color: #ccc !default;
$breadcrumb-active-color: $colors-greys-beta !default;
$breadcrumb-separator: '/' !default;

// Code
//

$code-color: #c7254e !default;
$code-bg: #f9f2f4 !default;
$kbd-color: #fff !default;
$kbd-bg: #333 !default;
$pre-bg: #f5f5f5 !default;
$pre-color: $colors-greys-alpha !default;
$pre-border-color: #ccc !default;
$pre-scrollable-max-height: 340px !default;

// Type
//

$component-offset-horizontal: 180px !default;
$text-muted: $colors-greys-beta !default;
$abbr-border-color: $colors-greys-beta !default;
$headings-small-color: $colors-greys-beta !default;
$blockquote-small-color: $colors-greys-beta !default;
$blockquote-font-size: ($font-size-base * 1.25) !default;
$blockquote-border-color: $colors-greys-beta !default;
$page-header-border-color: $colors-greys-beta !default;
$dl-horizontal-offset: $component-offset-horizontal !default;
$hr-border: $colors-greys-beta !default;
$list-inline-spacing-small: $atom-spacing !default;
$list-inline-spacing: $element-spacing !default;
$list-inline-spacing-large: $component-spacing !default;
