
@import '../loader.settings';

.#{$lib}three-bounce {
  margin: $loader-spinner-margin;
  text-align: center;
  width: $loader-size * 2;

  &__item {
    animation: three-bounce $loader-three-bounce-animation-duration ease-in-out 0s infinite both;
    background-color: $loader-color;
    border-radius: 100%;
    display: inline-block;
    height: $loader-size / 2;
    width: $loader-size / 2;

    .#{$lib}inverted & {
      background-color: $loader-color-inverted;
    }
  }

  &__item1 {
    animation-delay: -$loader-three-bounce-delay-range;
  }

  &__item2 {
    animation-delay: -$loader-three-bounce-delay-range / 2;
  }
}

@keyframes three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
