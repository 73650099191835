@function decimal-round ($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number

  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';

    @return $number;
  }
  // $digits must be a unitless number

  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';

    @return $number;
  }

  @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';

    @return $number;
  }

  @if $digits > 0 {
    @for $i from 1 through $digits {
      $n: $n * 10;
    }
  }

  @if $mode == round {
    @return round($number * $n) / $n;
  }

  @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  }

  @else if $mode == floor {
    @return floor($number * $n) / $n;
  }

  @else {
    @warn '#{ $mode } is undefined keyword.';

    @return $number;
  }
}

/**
 * Used for hr border to prevent breaking vertical rhythm.
 */

@mixin trailing-border($color) {
  position: relative;

  &::after {
    border: 1px solid $color;
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

/**
 * Inline list spacing.
 */

@mixin inline-list($amount) {
  $margin: ($amount / 2);
  margin-left: -$margin;

  > .#{$lib}list__item {
    display: inline-block;
    padding-left: $margin;
    padding-right: $margin;
  }
}
