/**
 * Settings.
 */
$hamburger-padding-x: $atom-spacing !default;
$hamburger-padding-y: $atom-spacing !default;
$hamburger-layer-width: 20px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: #000 !default;
$hamburger-layer-color-active: $hamburger-layer-color !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

/**
 * To use CSS filters as the hover effect instead of opacity,
 * set $hamburger-hover-use-filter as true and
 * change the value of $hamburger-hover-filter accordingly.
 */
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(100%) !default;

$hamburger-height: ($hamburger-layer-height * 3) + ($hamburger-layer-spacing * 2) !default;

/**
 * Types (Remove or comment out what you don’t need)
 */
$hamburger-types: (
  '3dx',
  '3dx-r',
  '3dy',
  '3dy-r',
  '3dxy',
  '3dxy-r',
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spin,
  spin-r,
  spring,
  spring-r,
  stand,
  stand-r,
  squeeze,
  vortex,
  vortex-r
) !default;
