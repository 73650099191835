/**
/* Font mixins.
 */

@mixin font-style-default() {
  font-family: $font-family-base;
  font-weight: normal;
}

@mixin font-style-bold() {
  font-family: $font-family-bold;
  font-weight: bold;
}

@mixin font-style-italic() {
  font-family: $font-family-italic;
  font-weight: normal;
}

@mixin font-style-standfirst() {
  font-family: $font-family-standfirst;
  font-weight: normal;
}

@mixin heading-style-default() {
  font-family: $font-family-heading;
  font-weight: $font-family-heading-weight;
}

@mixin heading-style-italic() {
  font-family: $font-family-heading-italic;
  font-weight: $font-family-heading-weight;
}

@mixin heading-style-display() {
  font-family: $font-family-heading-display;
  font-weight: $font-family-heading-display-weight;
}

@mixin heading-style-display-italic() {
  font-family: $font-family-heading-display-italic;
  font-weight: $font-family-heading-display-weight;
}
