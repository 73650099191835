//
// Typography
// --------------------------------------------------

// Body text
// ------------------------

.lead {
  font-size: floor(($font-size-base * 1.15));
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: $line-height-computed;
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%

small,
.small {
  font-size: floor((100% * $font-size-small / $font-size-base));
}

mark,
.mark {
  background-color: $state-warning-bg;
  padding: 0.2em;
}

// Contextual backgrounds
// For now we'll leave these alongside the text classes until v4 when we can
// safely shift things around (per SemVer rules).

.bg-primary {
  // Given the contrast here, this is the only class to have its color inverted
  // automatically.
  color: #fff;
}

// Page header
// -------------------------

.page-header {
  border-bottom: 1px solid $page-header-border-color;
  margin: ($line-height-computed * 2) 0 $line-height-computed;
  padding-bottom: (($line-height-computed / 2) - 1);
}

// Lists
// -------------------------

// Unordered and Ordered lists

ul,
ol {
  margin-bottom: $line-height-computed;
  margin-top: 0;

  ul,
  ol {
    margin-bottom: 0;
  }
}

// List options

// [converter] extracted from `.list-unstyled` for libsass compatibility

@mixin list-unstyled {
  list-style: none;
  padding-left: 0;
}
// [converter] extracted as `@mixin list-unstyled` for libsass compatibility

.#{$lib}list--unstyled {
  @include list-unstyled;
}

/**
 * Inline turns list items into inline-block.
 */
.#{$lib}list--inline {
  @include list-unstyled;

  @include inline-list($list-inline-spacing);
}

/**
 * List sizes.
 */
.#{$lib}list--small {
  &.#{$lib}list--inline {
    @include inline-list($list-inline-spacing-small);
  }
}

.#{$lib}list--large {
  &.#{$lib}list--inline {
    @include inline-list($list-inline-spacing-large);
  }
}

// Description Lists

dl {
  margin-bottom: $line-height-computed;
  margin-top: 0; // Remove browser default
}

dt,
dd {
  line-height: $line-height-base;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0; // Undo browser default
}

// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).

.#{$lib}dl--horizontal {
  dd {
    @include clearfix; // Clear the floated `dt` if an empty `dd` is present
  }
}

// Misc
// -------------------------

// Abbreviations and acronyms abbr[title],
// add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257

.#{$lib}abbr[data-original-title] {
  border-bottom: 1px dotted $abbr-border-color;
  cursor: help;
}

.#{$lib}abbr--unstyled {
  text-decoration: none;
}

.initialism {
  font-size: 90%;
}

// Blockquotes

blockquote {
  border-left: 5px solid $blockquote-border-color;
  font-size: $blockquote-font-size;
  margin: 0 0 $line-height-computed;
  padding: ($line-height-computed / 2) $line-height-computed;

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660

  footer,
  small,
  .small {
    color: $blockquote-small-color;
    display: block;
    font-size: 80%; // back to default font-size
    line-height: $line-height-base;

    &::before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

// Opposite alignment of blockquote
//
// Heads up: `blockquote.pull-right` has been deprecated as of v3.1.0.

.blockquote-reverse,
.#{$lib}blockquote.pull-right {
  border-left: 0;
  border-right: 5px solid $blockquote-border-color;
  padding-left: 0;
  padding-right: 15px;
  text-align: right;

  // Account for citation

  footer,
  small,
  .small {
    &::before {
      content: '';
    }

    &::after {
      content: '\00A0 \2014'; // nbsp, em dash
    }
  }
}

// Addresses

address {
  font-style: normal;
  line-height: $line-height-base;
  margin-bottom: $line-height-computed;
}
