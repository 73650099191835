.#{$lib}loader {
  display: block;
  font-size: $loader-default-font-size;
  height: $loader-default-height;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: $loader-default-width;

  &::before {
    border: $loader-default-border-bg;
    border-radius: 500rem;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::after {
    animation: $loader-animation-default-keyframe $loader-animation-default-duration $loader-animation-default-ease;
    animation-iteration-count: $loader-animation-default-iteration-count;
    border-color: $loader-animation-border-color;
    border-radius: 500rem;
    border-style: $loader-animation-border-style;
    border-width: $loader-animation-border-width;
    box-shadow: $loader-animation-box-shadow;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .#{$lib}inverted & {
      border-color: $loader-animation-border-color-inverted;
    }
  }
}
