// --------------------------
//      Loader structure
// --------------------------

@import 'loader.settings';

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
