@import 'alerts.settings';

#{$alert} {
  &--block {
    margin-bottom: $line-height-computed;
    padding: $alert-padding;
  }

  /**
   * Headings for larger alerts
   */
  &__title {
    margin-right: $atom-spacing;
    margin-top: 0;
  }

  &__content {
    margin-top: 0;
  }

  /**
   * Improve alignment and spacing of inner content
   */
  p,
  ul {
    margin-bottom: 0;
  }

  p + p {
    margin-top: 5px;
  }

  &__icon {
    content: '';
    height: 16px;
    left: $alert-icon-padding;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
  }

  &--icon {
    padding-left: ($element-spacing * 4);
    position: relative;
  }
}

/**
 * Dismissible alerts Expand the right padding and account for the close button's positioning.
 */
#{$alert}-dismissable {
  padding-right: ($alert-padding + 20);

  /**
   * Adjust close link position
   */
  .close {
    position: relative;
    right: -21px;
    top: -2px;
  }
}
