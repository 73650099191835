/**
 * Typography.
 */

/**
 * Font-sizes.
 */
$font-size-base: 16px !default;
$font-size-base-int: 1 !default;
$font-size-default: 16px !default;

/**
 * Bespoke typography config.
 */
$line-height-base: 2 !default;

// --------------------------------------
//      PlumberSass vertical rhythm
// --------------------------------------

/**
 * Font Family baseline.
 */
$default-font-baseline: 0.158 !default; // Helvetica baseline

/**
 * Copy.
 */
$copy-max-length: 700px !default;
